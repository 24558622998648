import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import {
    getIndividualCategories,
    getIndividualTags,
    getIndividualColors,
    getProductsIndividualSizes, getIndividualGenders, getIndividualRetailers, getShowProducts
} from "../../helpers/product";

import ShopCategories from "../../components/product/ShopCategories";
import ShopColor from "../../components/product/ShopColor";
import ShopSize from "../../components/product/ShopSize";
import ShopTag from "../../components/product/ShopTag";
import ShopGender from "../../components/product/ShopGender";
import ShopMultiRangeSlider from "../../components/MultiRangeSlider";
import ShopRetailer from "../../components/product/ShopRetailer";
import ShopTagColor from "../../components/product/ShopTagColor";
import ShopMultiRangeDropSlider from "../../components/MultiRangeSliderDrop";
import ShopMultiRangeWeighSlider from "../../components/MultiRangeSliderWeigh";
import ShopMotorPref from "../../components/product/ShopMotorPref";
import ShopPain from "../../components/product/ShopPain";
import ShopShoeType from "../../components/product/ShopShoeType";
import { Col, Row } from "react-bootstrap";
import { calculateShoeType } from "../../pages/other/ProResearchHomePage";
import ShopStrideAnalysis from "../../components/product/ShopStrideAnalysis";
import ShopShoeUsage from "../../components/product/ShopShoeUsage";
import ShopShoeDistance from "../../components/product/ShopShoeDistance";
import ShopIsPain from "../../components/product/ShopIsPain";
import ShopIsMotorPref from "../../components/product/ShopIsMotorPref";
import ShopFitSize from "../../components/product/ShopFitSize";
import ShopShoeLongFlexibility from "../../components/product/ShopShoeLongFlexibility";
import ShopShoeTransFlexibility from "../../components/product/ShopShoeTransFlexibility";
import { getWithExpiry } from "../../pages/home/HomeFashionTwo";
import ShopCushioningType from "../../components/product/ShopCushioningType";
import { isMobileOnly } from "react-device-detect";
import ShopCarbonPlate from "../../components/product/ShopCarbonPlate";
import ShopPlasticPlate from "../../components/product/ShopPlasticPlate";

const ShopSidebar = ({ location,
                       currentQuery,
                       setCurrentQuery,
                       products,
                       getCategorySortParams,
                       getBrandSortParams,
                       getGenderSortParams,
                       getColorSortParams,
                       getSizeSortParams,
                       getPriceSortParams,
                       getRetailerSortParams,
                       sideSpaceClass,
                       sortedHighPrice,
                       sortedLowPrice,
                       uniqueGenders,
                       uniqueSizes,
                       uniqueBrands,
                       uniqueColors,
                       uniqueRetailers,
                       uniqueCategories,
                       mode
                     }) => {


  const [motorSortValue, setMotorSortValue] = useState('');
  const [motorSortType, setMotorSortType] = useState('');
  const [isMotorSortValue, setIsMotorSortValue] = useState('');
  const [isMotorSortType, setIsMotorSortType] = useState('');
  const [painSortValue, setPainSortValue] = useState('');
  const [painSortType, setPainSortType] = useState('');
  const [isPainSortValue, setIsPainSortValue] = useState('');
  const [isPainSortType, setIsPainSortType] = useState('');
  const [shoeTypeSortValue, setShoeTypeSortValue] = useState('');
  const [shoeTypeSortType, setShoeTypeSortType] = useState('');
  const [currentShoeType, setCurrentShoeType] = useState([]);
  const [strideAnalysisSortValue, setStrideAnalysisSortValue] = useState('');
  const [strideAnalysisSortType, setStrideAnalysisSortType] = useState('');
  const [shoeUsageSortValue, setShoeUsageSortValue] = useState('');
  const [shoeUsageSortType, setShoeUsageSortType] = useState('');
  const [fitSizeSortValue, setFtSizeSortValue] = useState('');
  const [fitSizeSortType, setFtSizeSortType] = useState('');
  const [isPerformanceShoe, setIsPerformanceShoe] = useState(false);
  const [distanceSortValue, setDistanceSortValue] = useState('');
  const [distanceSortType, setDistanceSortType] = useState('');
  const [longFlexibilitySortValue, setLongFlexibilitySortValue] = useState('');
  const [longFlexibilitySortType, setLongFlexibilitySortType] = useState('');
  const [transFlexibilitySortValue, setTransFlexibilitySortValue] = useState('');
  const [transFlexibilitySortType, setTransFlexibilitySortType] = useState('');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(location && location.pathname == "/products/electronic" ? 1500 : 500);
  const [minDrop, setMinDrop] = useState(0);
  const [maxDrop, setMaxDrop] = useState(20);
  const [minWeight, setMinWeight] = useState(0);
  const [maxWeight, setMaxWeight] = useState(500);
  const [priceSortValue, setPriceSortValue] = useState(null);
  const [cushioningTypeSortType, setCushioningTypeSortType] = useState('');
  const [cushioningTypeSortValue, setCushioningTypeSortValue] = useState('');
  const [dropSortType, setDropSortType] = useState('');
  const [weightSortType, setWeightSortType] = useState('');
  const [carbonPlateSortType, setCarbonPlateSortType] = useState('');
  const [carbonPlateSortValue, setCarbonPlateSortValue] = useState('');
  const [plasticPlateSortType, setPlasticPlateSortType] = useState('');
  const [plasticPlateSortValue, setPlasticPlateSortValue] = useState('');

  useEffect(() => {
    if (currentQuery) {
      setCurrentShoeType(calculateShoeType(currentQuery.motor, currentQuery.pains, currentQuery.isPain, currentQuery.isMotor))
      if(currentQuery.types == "Axé performance"){
        setIsPerformanceShoe(true)
      } else {
        setIsPerformanceShoe(false)
      }
    } else {
      setIsPerformanceShoe(false)
      setCurrentQuery([])
    }
}, [currentQuery]);

  useEffect(() => {
    if(currentQuery && !currentQuery.carbonPlate){
      if(mode && mode == "manual" && location.pathname == "/products/shoes"){
        setCurrentQuery({...currentQuery, carbonPlate: "Oui"})
      }
    }
    if(currentQuery && !currentQuery.plasticPlate){
      if(mode && mode == "manual" && location.pathname == "/products/shoes"){
        setCurrentQuery({...currentQuery, plasticPlate: "Oui"})
      }
    }
    
  }, [mode, currentQuery, location])

  return (
    <div className={`sidebar-style ${sideSpaceClass ? sideSpaceClass : ""}`}>
      {/* shop search */}
      {/*<ShopSearch />*/}

      {/* filter by gender */}
        {
            location.pathname != "/products/vouchers"
                ?
      <ShopGender
          location={location}
          genders={uniqueGenders}
          getSortParams={getGenderSortParams}
          currentQuery={currentQuery}
          setCurrentQuery={setCurrentQuery}
      /> : null }


      {
        mode == "auto" ?
        <Row>
          <Col>
            <ShopIsMotorPref
              location={location}
              motors={["Oui", "Non"]}
              getSortParams={setIsMotorSortType}
              setGenderSortValue={setIsMotorSortValue}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
            />
          </Col>
        </Row>
        : null
      }

      {
        mode == "manual" ?
          <Row>
            <Col>
              <ShopCushioningType
                location={location}
                cushioningTypes={["Mou", "Ferme", "Adaptatif"]}
                getSortParams={setCushioningTypeSortType}
                setGenderSortValue={setCushioningTypeSortValue}
                currentQuery={currentQuery}
                setCurrentQuery={setCurrentQuery}
              />
            </Col>
          </Row>
        : null 
      }
      {
        mode == "manual" ?
          <Row>
            <Col>
              <ShopCarbonPlate
                location={location}
                carbonPlates={["Oui", "Non"]}
                getSortParams={setCarbonPlateSortType}
                setGenderSortValue={setCarbonPlateSortValue}
                currentQuery={currentQuery}
                setCurrentQuery={setCurrentQuery}
                isHome={true}
              />
            </Col>
          </Row>
        : null 
      }
      {
        mode == "manual" ?
          <Row>
            <Col>
              <ShopPlasticPlate
                location={location}
                plasticPlates={["Oui", "Non"]}
                getSortParams={setPlasticPlateSortType}
                setGenderSortValue={setPlasticPlateSortValue}
                currentQuery={currentQuery}
                setCurrentQuery={setCurrentQuery}
                isHome={true}
              />
            </Col>
          </Row>
        : null 
      }
      {
        mode == "manual" ?
        <Row className='justify-content-center'>
          <Col>
            <ShopMultiRangeWeighSlider
              sortedHighPrice={maxWeight}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
              sortedLowPrice={minWeight}
              getSortParams={setWeightSortType}
            />
          </Col>
        </Row>
        : null 
      }
      {
        mode == "manual" ?
        <Row className='justify-content-center'>
          <Col>
            <ShopMultiRangeDropSlider
              sortedHighPrice={maxDrop}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
              sortedLowPrice={minDrop}
              getSortParams={setDropSortType}
            />
          </Col>
        </Row>
        : null 
      }

      {
        mode == "manual" ?
          <Row className='justify-content-center'>
            <Col>
              {/* filter by price */}
              <ShopMultiRangeSlider 
                sortedHighPrice={maxPrice}
                currentQuery={currentQuery}
                setCurrentQuery={setCurrentQuery}
                sortedLowPrice={minPrice}
                getSortParams={getPriceSortParams}
              />
            </Col>
          </Row>
          : null 
      }
      

      {
        mode == "auto" && currentQuery && currentQuery.isMotor && currentQuery.isMotor == "Oui" ?
          <ShopMotorPref
            location={location}
            motors={["Projection avant gauche", "Projection avant droit", "Projection arrière gauche", "Projection arrière droit"]}
            getSortParams={setMotorSortType}
            setGenderSortValue={setMotorSortValue}
            currentQuery={currentQuery}
            setCurrentQuery={setCurrentQuery}
          />
        : null
      }
      

      {
        mode == "auto" ?
        <Row>
          <Col>
            <ShopIsPain
              location={location}
              pains={["Oui", "Non"]}
              getSortParams={setIsPainSortType}
              setGenderSortValue={setIsPainSortValue}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
            />
          </Col>
        </Row>
        : null
      }
      
                    
      {
        mode == "auto" && currentQuery.isPain && currentQuery.isPain == "Oui" ?
        <ShopPain
            location={location}
            pains={["Douleur pied-talon", "Douleur chaîne postérieure", "Douleur chaîne antérieure", "Douleur genou-hanche-dos"]}
            getSortParams={setPainSortType}
            setGenderSortValue={setPainSortValue}
            currentQuery={currentQuery}
            setCurrentQuery={setCurrentQuery}
          /> : null
      }


        {
          mode == "auto" && currentShoeType && currentShoeType.length > 0 ?
          <Row>
            <Col>
              <ShopShoeType
                location={location}
                types={currentShoeType}
                getSortParams={setShoeTypeSortType}
                setGenderSortValue={setShoeTypeSortValue}
                currentQuery={currentQuery}
                setCurrentQuery={setCurrentQuery}
              />
            </Col>
          </Row>
          : null
        }

        {
          mode == "auto" && isPerformanceShoe ? 
          <Row>
            <Col>
              <ShopStrideAnalysis
                location={location}
                types={["Foulée antérieure", "Foulée postérieure"]}
                getSortParams={setStrideAnalysisSortType}
                setGenderSortValue={setStrideAnalysisSortValue}
                currentQuery={currentQuery}
                setCurrentQuery={setCurrentQuery}
              />
            </Col>
          </Row>
          : null 
        }

        <Row>
          <Col>
            <ShopShoeDistance
              location={location}
              distances={["Courte distance (jusqu'à 10km)", "Longue distance"]}
              getSortParams={setDistanceSortType}
              setGenderSortValue={setDistanceSortValue}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <ShopShoeUsage
              location={location}
              usages={["Gravel", "Running", "Trail"]}
              getSortParams={setShoeUsageSortType}
              setGenderSortValue={setShoeUsageSortValue}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <ShopFitSize
              location={location}
              fitsizes={["Serré", "Normal", "Large"]}
              getSortParams={setFtSizeSortType}
              setGenderSortValue={setFtSizeSortValue}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <ShopShoeLongFlexibility
              location={location}
              usages={["Souple", "Medium", "Rigide"]}
              getSortParams={setLongFlexibilitySortType}
              setGenderSortValue={setLongFlexibilitySortValue}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <ShopShoeTransFlexibility
              location={location}
              usages={["Souple", "Medium", "Rigide"]}
              getSortParams={setTransFlexibilitySortType}
              setGenderSortValue={setTransFlexibilitySortValue}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
            />
          </Col>
        </Row>

        {
        mode == "auto" ?
        <Row className='justify-content-center'>
          <Col>
            <ShopMultiRangeWeighSlider
              sortedHighPrice={maxWeight}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
              sortedLowPrice={minWeight}
              getSortParams={setWeightSortType}
            />
          </Col>
        </Row>
        : null 
      }
      {
        mode == "auto" ?
        <Row className='justify-content-center'>
          <Col>
            <ShopMultiRangeDropSlider
              sortedHighPrice={maxDrop}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
              sortedLowPrice={minDrop}
              getSortParams={setDropSortType}
            />
          </Col>
        </Row>
        : null 
      }
      {
        mode == "auto" ?
        <Row className='justify-content-center'>
          <Col>
            {/* filter by price */}
            <ShopMultiRangeSlider 
              sortedHighPrice={maxPrice}
              currentQuery={currentQuery}
              setCurrentQuery={setCurrentQuery}
              sortedLowPrice={minPrice}
              getSortParams={getPriceSortParams}
            />
          </Col>
        </Row>
        : null 
      }

        

      {/* filter by categories */}
        {/*{
            location.pathname != "/products/vouchers"
                ?
      <ShopCategories
          location={location}
          categories={uniqueCategories}
        getSortParams={getCategorySortParams}
        currentQuery={currentQuery}
        setCurrentQuery={setCurrentQuery}
      /> : null }
      */}

        {/* filter by tag */}
        {
            location.pathname != "/products/vouchers"
                ?
        <ShopTag
            location={location}
            currentQuery={currentQuery}
            setCurrentQuery={setCurrentQuery}
            tags={uniqueBrands}
            getSortParams={getBrandSortParams} /> : null }

        {/* filter by color */}
        {
          /* 
             {
            location.pathname != "/products/nutrition"&& location.pathname != "/products/vouchers"
                ?
                <ShopTagColor
                    location={location}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                    colors={uniqueColors}
                    getSortParams={getColorSortParams} /> : null
        }
          */
        }


        {/* filter by size */}
        {
          /*

        <ShopRetailer
            location={location}
            currentQuery={currentQuery}
            setCurrentQuery={setCurrentQuery}
            retailers={uniqueRetailers}
            getSortParams={getRetailerSortParams} />
          */
        }

      {/* filter by size */}
        {
            location.pathname != "/products/accessories" && location.pathname != "/products/nutrition" && location.pathname != "/products/electronic" && location.pathname != "/products/vouchers"
            ?
                <ShopSize
                    location={location}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                    sizes={uniqueSizes}
                    getSortParams={getSizeSortParams} />
                : null

        }
    </div>
  );
};

ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  getCategorySortParams: PropTypes.func,
  getGenderSortParams: PropTypes.func,
  getColorSortParams: PropTypes.func,
  getSizeSortParams: PropTypes.func,
  getPriceSortParams: PropTypes.func,
  getRetailerSortParams: PropTypes.func,
  products: PropTypes.array,
  sortedLowPrice: PropTypes.any,
  sortedHighPrice: PropTypes.any,
  sideSpaceClass: PropTypes.string,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
    location: PropTypes.string
};

export default ShopSidebar;
