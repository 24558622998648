import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {deleteActiveGenderSort, setActiveGenderSort, slugify} from "../../helpers/product";
import { isMobileOnly} from "react-device-detect";
import qs from "query-string";
import {useTranslation} from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { use } from "i18next";
import { Spinner } from "react-bootstrap";

const ShopProductChoice = ({ location, currentQuery, setCurrentQuery, productsChoice, getSortParams, isHome }) => {
    const [isSmallScreen, setSmallScreen] = useState(false);
    const [productName, setProductName] = useState(null);
    const [data, setData] = useState([]);
    const [currentSearchData, setCurrentSearchData] = useState(currentQuery.productSlug)
    const [defaultValueProductName, setDefaultValueProductName] = useState(currentQuery.productName ? decodeURI(currentQuery.productName) : null);
    const [loading, setLoading] = useState(currentQuery.productSlug && currentQuery.productName);
    const [currentGender, setCurrentGender] = useState(currentQuery.genders);

    useEffect(() => {
        if(currentQuery && currentQuery.genders)
            if(currentQuery.genders != currentGender){
                setProductName(null);
                setCurrentSearchData(null);
                setData([])
            }
            setCurrentGender(currentQuery.genders)
    }, [currentQuery]);

    const parseProductName = (name) => {
        let output = name
        if(name){
            output = name.replace("%", "percent")
        }
        return output
    }

    const parseProductNameQuery = (name) => {
        let output = name
        if(name){
            output = name.replace("percent", "%")
        }
        return output
    }

    useEffect(() => {
        if(productName && productName.shortURL && productName.name){
            setCurrentQuery({ ...currentQuery, productSlug: productName.shortURL.replace(/\?.*/,''), productName: encodeURI(parseProductName(productName.name))});
        } else {
            setCurrentQuery({ ...currentQuery, productSlug: null, productName: null});
        }
    }, [productName])

    useEffect(() => {
        if(defaultValueProductName && data && data.length > 0 && loading){
            data.map(p => {
                if (p.name == parseProductNameQuery(defaultValueProductName)){
                    setProductName(p);
                    setLoading(false)
                }
            })
        }
    }, [defaultValueProductName, data])

    const defaultProps = {
        options: data,
        getOptionLabel: (option) => option.name,
    };

    const fetchSearchProductsData = (slug, page) => {
        let product = slugify(slug)
        if (product.length > 1) {
            let query = 'https://front-professional-api.therunningcollective.fr/search-products?keywords=' + product
            if(currentQuery && currentQuery.genders && currentQuery.genders != "Unisex"){
            query = query + "&genders=" + currentQuery.genders 
            }
            
            axios
                .get(query, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-pro-client-token')).token}`
                }})
                .then((res) => {
                if(res.data){
                    setData(res.data);
                } else {
                    setData([]);
                }
                })
                .catch((err) => {
                if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
                    window.location.href = "/logout"
                }
                })
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (currentSearchData && currentSearchData.length > 2) {
                let resWithoutSpace = currentSearchData.replaceAll(" ", "-")
                let res = resWithoutSpace.toLowerCase()
                fetchSearchProductsData(currentSearchData)
            } else {
                setData([])
            }
        }, 200)
        return () => clearTimeout(delayDebounceFn)
        }, [currentSearchData]
    )

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        }
    }, [])

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        }
    })

  return (
    <div className="sidebar-widget mt-10">
        {
            isMobileOnly || isSmallScreen ?
                <div>
                    <h4 className="pro-sidebar-title">{isHome ? "Quelle est la paire actuelle la plus utilisée ? (facultatif)" : "Paire actuelle la plus utilisée :"}</h4>
                    <div className="sidebar-widget-tag-carbon-plate mt-10" style={{display: 'block'}}>
                        {
                            loading ?
                                <div style={{textAlign: 'center'}}>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                    </Spinner>
                                </div>
                        : 
                            <Autocomplete
                                {...defaultProps}
                                id="grid-choose-woman"
                                clearOnEscape
                                value={productName}
                                onChange={(event, newValue) => {
                                    setProductName(newValue);
                                }}
                                onInputChange={(event, newValue, reason) => {
                                    if (reason === 'reset') {
                                        setCurrentSearchData(null)
                                        return
                                      } else {
                                        setCurrentSearchData(newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Rechercher un produit" />
                                )}
                            />
                        }
                    </div>
                </div>
                :
                <div>
                    <h4 className="pro-sidebar-title">{isHome ? "Quelle est la paire actuelle la plus utilisée ? (facultatif)" : "Paire actuelle la plus utilisée :"}</h4>
                    <div className="sidebar-widget-tag-carbon-plate mt-10">
                        {
                            loading ?
                                <div style={{textAlign: 'center'}}>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                    </Spinner>
                                </div>
                        : 
                            <Autocomplete
                                {...defaultProps}
                                id="grid-choose-woman"
                                clearOnEscape
                                value={productName}
                                onChange={(event, newValue) => {
                                    setProductName(newValue);
                                }}
                                onInputChange={(event, newValue, reason) => {
                                    if (reason === 'reset') {
                                        setCurrentSearchData(null)
                                        return
                                      } else {
                                        setCurrentSearchData(newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Rechercher un produit" />
                                )}
                            />
                        }
                    </div>
                </div>
        }
    </div>
  );
};

export function getLabel(productChoice, t){
    switch (productChoice){
        case "Women":
            return t('productChoice')
        case "Men":
            return "Homme"
        case "Unisex":
            return "Non Genré"
        default:
            return
    }
}

export function ConstructRemoveGenderQueryPath(query, sizeToRemove) {
    if (query && query.productChoices) {
        let productChoices = query.productChoices.split('_');
        productChoices = productChoices.filter(size => {
            return size !== sizeToRemove;
        })
        if (productChoices.length > 0) {
            let newSizeQuery = ""
            productChoices.map((size, index) => {
                if (index == 0){
                    newSizeQuery += size
                } else {
                    newSizeQuery +=  "_" + size
                }
            })
            return newSizeQuery
        } else {
            return null
        }

    }
    return null
}

ShopProductChoice.propTypes = {
  getSortParams: PropTypes.func,
  tags: PropTypes.array,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
    location: PropTypes.string
};

export default ShopProductChoice;
