import PropTypes from "prop-types";
import React, { useEffect } from "react";
import ProductgridList from "./ProductgridList";

const ShopProducts = ({ products, layout, setFavoriteProducts, favoriteProducts, physioMode}) => {
    return (
    <div className="shop-bottom-area mt-10">
      <div className={`row ${layout ? layout : ""}`}>
        <ProductgridList products={products} spaceBottomClass="mb-10" setFavoriteProducts={setFavoriteProducts} favoriteProducts={favoriteProducts} physioMode={physioMode}/>
      </div>
    </div>
  );
};

ShopProducts.propTypes = {
  layout: PropTypes.string,
  products: PropTypes.array,
  favoriteProducts: PropTypes.any,
  setFavoriteProducts: PropTypes.func,
};

export default ShopProducts;
