import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {deleteActiveGenderSort, setActiveGenderSort} from "../../helpers/product";
import { isMobileOnly} from "react-device-detect";
import qs from "query-string";
import {useTranslation} from "react-i18next";

const ShopPlasticPlate = ({ location, currentQuery, setCurrentQuery, plasticPlates, getSortParams, isHome }) => {
  const [isSmallScreen, setSmallScreen] = useState(false);
    const { t } = useTranslation()

  const query = qs.parse(location.search, {
      ignoreQueryPrefix: true
  });
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-plastic-plate button"
  );

    filterButtons.forEach(item => {
        item.classList.remove("active");
    });

    if (query && query.plasticPlate) {
        let plasticPlates = query.plasticPlate.split('_');
        plasticPlates.map(size => {
            var element = document.getElementById("plasticPlate"+size);
            if (element){
                element.classList.add("active")
            }
        })
    }

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        }
    }, [])

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        }
    })

    function constructAddQueryPath(query, size) {
        if (query && query.plasticPlates) {
            return query.plasticPlates + "_" + size
        }
        return size
    }

  return (
    <div className="sidebar-widget mt-10">
        {
            isMobileOnly || isSmallScreen ?
                <div>
                    <h4 className="pro-sidebar-title">{isHome ? "Avec plaque platique ?" : "Plaque plastique :"}</h4>
                    <div className="sidebar-widget-tag-plastic-plate mt-10" style={{display: 'block'}}>
                        {plasticPlates ? (
                            <ul>
                                {plasticPlates.map((plasticPlate, key) => {
                                    return (
                                        <li key={"plasticPlate"+key}>
                                            <button
                                                className="toto"
                                                id={"plasticPlate"+plasticPlate}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("plasticPlate", "");
                                                        setCurrentQuery({ ...currentQuery, plasticPlate: ""});
                                                        deleteActiveGenderSort(e);
                                                    } else {
                                                        getSortParams("plasticPlate", plasticPlate);
                                                        setCurrentQuery({ ...currentQuery, plasticPlate: plasticPlate});
                                                        setActiveGenderSort(e);
                                                    }
                                                }}
                                            >
                                                {t(plasticPlate.toLowerCase())}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
                :
                <div>
                    <h4 className="pro-sidebar-title">{isHome ? "Avec plaque platique ?" : "Plaque plastique :"}</h4>
                    <div className="sidebar-widget-tag-plastic-plate mt-10">
                        {plasticPlates ? (
                            <ul>
                                {plasticPlates.map((plasticPlate, key) => {
                                    return (
                                        <li key={"plasticPlate"+key}>
                                            <button
                                                className="toto"
                                                id={"plasticPlate"+plasticPlate}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("plasticPlate", "");
                                                        setCurrentQuery({ ...currentQuery, plasticPlate: ""});
                                                        deleteActiveGenderSort(e);
                                                    } else {
                                                        getSortParams("plasticPlate", plasticPlate);
                                                        setCurrentQuery({ ...currentQuery, plasticPlate: plasticPlate});
                                                        setActiveGenderSort(e);
                                                    }
                                                }}
                                            >
                                                {t(plasticPlate.toLowerCase())}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
        }
    </div>
  );
};

export function getLabel(plasticPlate, t){
    switch (plasticPlate){
        case "Women":
            return t('plasticPlate')
        case "Men":
            return "Homme"
        case "Unisex":
            return "Non Genré"
        default:
            return
    }
}

export function ConstructRemoveGenderQueryPath(query, sizeToRemove) {
    if (query && query.plasticPlates) {
        let plasticPlates = query.plasticPlates.split('_');
        plasticPlates = plasticPlates.filter(size => {
            return size !== sizeToRemove;
        })
        if (plasticPlates.length > 0) {
            let newSizeQuery = ""
            plasticPlates.map((size, index) => {
                if (index == 0){
                    newSizeQuery += size
                } else {
                    newSizeQuery +=  "_" + size
                }
            })
            return newSizeQuery
        } else {
            return null
        }

    }
    return null
}

ShopPlasticPlate.propTypes = {
  getSortParams: PropTypes.func,
  tags: PropTypes.array,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
    location: PropTypes.string
};

export default ShopPlasticPlate;
