import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import ShopSidebar from "../../wrappers/product/ShopSidebar";
import ShopGender from "../product/ShopGender";
import ShopCategories from "../product/ShopCategories";
import ShopTag from "../product/ShopTag";
import ShopTagColor from "../product/ShopTagColor";
import ShopMultiRangeSlider from "../MultiRangeSlider";
import ShopRetailer from "../product/ShopRetailer";
import ShopSize from "../product/ShopSize";
import {
    getIndividualItemArray,
    removeActiveBrandsSort,
    removeActiveColorsSort,
    removeActiveGenderSort, removeActiveRetailersSort,
    removeActiveSizeSort
} from "../../helpers/product";
import {Divider} from "@mui/material";
import {Close} from "@material-ui/icons";
import {newCheckExistingSize} from "../../pages/shop-product/tools";
import ShopShoeType, { calculateShoeType } from "../product/ShopShoeType";
import ShopIsMotorPref from "../product/ShopIsMotorPref";
import ShopMotorPref from "../product/ShopMotorPref";
import ShopIsPain from "../product/ShopIsPain";
import ShopPain from "../product/ShopPain";
import { Col, Row } from "react-bootstrap";
import ShopStrideAnalysis from "../product/ShopStrideAnalysis";
import ShopShoeDistance from "../product/ShopShoeDistance";
import ShopShoeUsage from "../product/ShopShoeUsage";
import ShopFitSize from "../product/ShopFitSize";
import ShopShoeLongFlexibility from "../product/ShopShoeLongFlexibility";
import ShopShoeTransFlexibility from "../product/ShopShoeTransFlexibility";
import ShopCushioningType from "../product/ShopCushioningType";
import ShopMultiRangeWeighSlider from "../MultiRangeSliderWeigh";
import ShopMultiRangeDropSlider from "../MultiRangeSliderDrop";
import ShopCarbonPlate from "../product/ShopCarbonPlate";
import ShopPlasticPlate from "../product/ShopPlasticPlate";

const FilterMenu = ({ location,
                        pathname,
                         currentQuery,
                         setCurrentQuery,
                         products,
                         getCategorySortParams,
                         getBrandSortParams,
                         getGenderSortParams,
                         getColorSortParams,
                         getSizeSortParams,
                         getPriceSortParams,
                         getRetailerSortParams,
                         sideSpaceClass,
                         sortedHighPrice,
                         sortedLowPrice,
                         uniqueGenders,
                         uniqueSizes,
                         uniqueBrands,
                         uniqueColors,
                         uniqueRetailers,
                         uniqueCategories,
                         setIsOpen,
                         mode
                     }) => {
    const closeMobileMenu = () => {
        setIsOpen(false)
    }

    const newUniqueSizes = newGetProductsIndividualSizes(pathname, uniqueSizes);
    const uniqueTags = uniqueBrands;
    let uniqueGender = uniqueGenders.filter((gender) => gender !== 'Unisex');
    uniqueGender = uniqueGender.filter((gender) => gender !== '');
    uniqueGender = uniqueGender.filter((gender) => gender !== 'Girls');
    uniqueGender = uniqueGender.filter((gender) => gender !== 'Boys');

    const [motorSortValue, setMotorSortValue] = useState('');
    const [motorSortType, setMotorSortType] = useState('');
    const [isMotorSortValue, setIsMotorSortValue] = useState('');
    const [isMotorSortType, setIsMotorSortType] = useState('');
    const [painSortValue, setPainSortValue] = useState('');
    const [painSortType, setPainSortType] = useState('');
    const [isPainSortValue, setIsPainSortValue] = useState('');
    const [isPainSortType, setIsPainSortType] = useState('');
    const [shoeTypeSortValue, setShoeTypeSortValue] = useState('');
    const [shoeTypeSortType, setShoeTypeSortType] = useState('');
    const [currentShoeType, setCurrentShoeType] = useState([]);
    const [strideAnalysisSortValue, setStrideAnalysisSortValue] = useState('');
    const [strideAnalysisSortType, setStrideAnalysisSortType] = useState('');
    const [isPerformanceShoe, setIsPerformanceShoe] = useState(false);
    const [distanceSortValue, setDistanceSortValue] = useState('');
    const [distanceSortType, setDistanceSortType] = useState('');
    const [shoeUsageSortValue, setShoeUsageSortValue] = useState('');
    const [shoeUsageSortType, setShoeUsageSortType] = useState('');
    const [fitSizeSortValue, setFtSizeSortValue] = useState('');
    const [fitSizeSortType, setFtSizeSortType] = useState('');
    const [longFlexibilitySortValue, setLongFlexibilitySortValue] = useState('');
    const [longFlexibilitySortType, setLongFlexibilitySortType] = useState('');
    const [transFlexibilitySortValue, setTransFlexibilitySortValue] = useState('');
    const [transFlexibilitySortType, setTransFlexibilitySortType] = useState('');
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(location && location.pathname == "/products/electronic" ? 1500 : 500);
    const [minDrop, setMinDrop] = useState(0);
    const [maxDrop, setMaxDrop] = useState(20);
    const [minWeight, setMinWeight] = useState(0);
    const [maxWeight, setMaxWeight] = useState(500);
    const [priceSortValue, setPriceSortValue] = useState(null);
    const [cushioningTypeSortType, setCushioningTypeSortType] = useState('');
    const [cushioningTypeSortValue, setCushioningTypeSortValue] = useState('');
    const [dropSortType, setDropSortType] = useState('');
    const [weightSortType, setWeightSortType] = useState('');
    const [carbonPlateSortType, setCarbonPlateSortType] = useState('');
    const [carbonPlateSortValue, setCarbonPlateSortValue] = useState('');
    const [plasticPlateSortType, setPlasticPlateSortType] = useState('');
    const [plasticPlateSortValue, setPlasticPlateSortValue] = useState('');

    useEffect(() => {
        if(currentQuery && !currentQuery.carbonPlate){
          if(mode && mode == "manual" && location.pathname == "/products/shoes"){
            setCurrentQuery({...currentQuery, carbonPlate: "Oui"})
          }
        }
        if(currentQuery && !currentQuery.plasticPlate){
          if(mode && mode == "manual" && location.pathname == "/products/shoes"){
            setCurrentQuery({...currentQuery, plasticPlate: "Oui"})
          }
        }
        
      }, [mode, currentQuery, location])
  
    useEffect(() => {
      if (currentQuery) {
        setCurrentShoeType(calculateShoeType(currentQuery.motor, currentQuery.pains, currentQuery.isPain, currentQuery.isMotor))
        if(currentQuery.types == "Axé performance"){
          setIsPerformanceShoe(true)
        } else {
          setIsPerformanceShoe(false)
        }
      } else {
        setIsPerformanceShoe(false)
        setCurrentQuery([])
      }
  }, [currentQuery]);

    return(
        <div
        style={{
            position: "fixed",
            zIndex: "999999999999",
            top: 0,
            right: 0,
            width: "100%",
            maxWidth: "100%",
            height: "100vh",
            padding: "10px 15px 0",
            transition: "0.6s",
            backgroundColor: "white",
        }}>
            <div>
                <button
                    style={{backgroundColor: "#343538", borderRadius: "50%", color: "white", position: "absolute",
                        right: "15px", top: "30px", border: "1px solid",
                    width: "40px", height: "40px"}}
                    onClick={() => closeMobileMenu()}
                >
                    <Close/>
                </button>
            </div>

            <div style={{
                overflow: "auto",
                height: "100%",
                paddingBottom: "150px"
            }}>
                <h2 style={{fontWeight: 500}}>Filtres</h2>
                <Divider/>
                {/* shop search */}
                {/*<ShopSearch />*/}
                {
                    location.pathname != "/products/accessories" && location.pathname != "/products/nutrition" && location.pathname != "/products/electronic"
                        ?
                        <>
                            <ShopGender
                                location={location}
                                genders={uniqueGender}
                                getSortParams={getGenderSortParams}
                                currentQuery={currentQuery}
                                setCurrentQuery={setCurrentQuery}
                            />
                            <Divider style={{marginTop: "10px"}}/>
                        </>


                        : null

                }

                {
                    mode == "manual" ?
                        <>
                            <ShopCushioningType
                                location={location}
                                cushioningTypes={["Mou", "Ferme", "Adaptatif"]}
                                getSortParams={setCushioningTypeSortType}
                                setGenderSortValue={setCushioningTypeSortValue}
                                currentQuery={currentQuery}
                                setCurrentQuery={setCurrentQuery}
                            />
                        </>
                    : null 
                }
                {
                    mode == "manual" ?
                    <Row>
                        <Col>
                        <ShopCarbonPlate
                            location={location}
                            carbonPlates={["Oui", "Non"]}
                            getSortParams={setCarbonPlateSortType}
                            setGenderSortValue={setCarbonPlateSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            isHome={true}
                        />
                        </Col>
                    </Row>
                    : null 
                }
                {
                    mode == "manual" ?
                    <Row>
                        <Col>
                        <ShopPlasticPlate
                            location={location}
                            plasticPlates={["Oui", "Non"]}
                            getSortParams={setPlasticPlateSortType}
                            setGenderSortValue={setPlasticPlateSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            isHome={true}
                        />
                        </Col>
                    </Row>
                    : null 
                }
                {
                    mode == "manual" ?
                        <>
                            <ShopMultiRangeWeighSlider
                            sortedHighPrice={maxWeight}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            sortedLowPrice={minWeight}
                            getSortParams={setWeightSortType}
                            />
                        </>
                    : null 
                }
                {
                    mode == "manual" ?
                        <>
                            <ShopMultiRangeDropSlider
                            sortedHighPrice={maxDrop}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            sortedLowPrice={minDrop}
                            getSortParams={setDropSortType}
                            />
                        </>
                    : null 
                }

                {
                    mode == "manual" ?
                    <>
                        {/* filter by price */}
                        <ShopMultiRangeSlider 
                            sortedHighPrice={maxPrice}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            sortedLowPrice={minPrice}
                            getSortParams={getPriceSortParams}
                        />
                        </>
                    : null 
                }

                {
                    mode == "auto" ?
                        <>
                            <ShopIsMotorPref
                                location={location}
                                motors={["Oui", "Non"]}
                                getSortParams={setIsMotorSortType}
                                setGenderSortValue={setIsMotorSortValue}
                                currentQuery={currentQuery}
                                setCurrentQuery={setCurrentQuery}
                            />      
                            <Divider style={{marginTop: "10px"}}/>  
                        </>
                    : null
                }
                

                {
                    mode == "auto" && currentQuery && currentQuery.isMotor && currentQuery.isMotor == "Oui" ?
                    <>
                        <ShopMotorPref
                            location={location}
                            motors={["Projection avant gauche", "Projection avant droit", "Projection arrière gauche", "Projection arrière droit"]}
                            getSortParams={setMotorSortType}
                            setGenderSortValue={setMotorSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                        />
                        <Divider style={{marginTop: "10px"}}/>  
                    </>
                    
                    : null
                }


                {
                    mode == "auto" ?
                        <>
                            <ShopIsPain
                                location={location}
                                pains={["Oui", "Non"]}
                                getSortParams={setIsPainSortType}
                                setGenderSortValue={setIsPainSortValue}
                                currentQuery={currentQuery}
                                setCurrentQuery={setCurrentQuery}
                            />
                            <Divider style={{marginTop: "10px"}}/>  
                        </>
                    : null
                }
                
                    
                {
                    mode == "auto" && currentQuery.isPain && currentQuery.isPain == "Oui" ?
                    <>
                        <ShopPain
                            location={location}
                            pains={["Douleur pied-talon", "Douleur chaîne postérieure", "Douleur chaîne antérieure", "Douleur genou-hanche-dos"]}
                            getSortParams={setPainSortType}
                            setGenderSortValue={setPainSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                        /> 
                        <Divider style={{marginTop: "10px"}}/>  
                    </>
                    : null
                }


                {
                    mode == "auto" && currentShoeType && currentShoeType.length > 0 ?
                        <>
                            <ShopShoeType
                                location={location}
                                types={currentShoeType}
                                getSortParams={setShoeTypeSortType}
                                setGenderSortValue={setShoeTypeSortValue}
                                currentQuery={currentQuery}
                                setCurrentQuery={setCurrentQuery}
                            />
                            <Divider style={{marginTop: "10px"}}/>
                        </>     
                        : null
                    }

                    {
                        mode == "auto" && isPerformanceShoe ? 
                        <>
                            <ShopStrideAnalysis
                                location={location}
                                types={["Foulée antérieure", "Foulée postérieure"]}
                                getSortParams={setStrideAnalysisSortType}
                                setGenderSortValue={setStrideAnalysisSortValue}
                                currentQuery={currentQuery}
                                setCurrentQuery={setCurrentQuery}
                            />
                            <Divider style={{marginTop: "10px"}}/>
                        </>
                        
                    : null 
                    }

                    <>
                        <ShopShoeDistance
                            location={location}
                            distances={["Courte distance (jusqu'à 10km)", "Longue distance"]}
                            getSortParams={setDistanceSortType}
                            setGenderSortValue={setDistanceSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            />
                        <Divider style={{marginTop: "10px"}}/>
                    </>

                    <>
                        <ShopShoeUsage
                            location={location}
                            usages={["Gravel", "Running", "Trail"]}
                            getSortParams={setShoeUsageSortType}
                            setGenderSortValue={setShoeUsageSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            />
                        <Divider style={{marginTop: "10px"}}/>
                    </>
                            

                    <>
                        <ShopFitSize
                            location={location}
                            fitsizes={["Serré", "Normal", "Large"]}
                            getSortParams={setFtSizeSortType}
                            setGenderSortValue={setFtSizeSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            />
                        <Divider style={{marginTop: "10px"}}/>
                    </>
                            

                    <>
                        <ShopShoeLongFlexibility
                            location={location}
                            usages={["Souple", "Medium", "Rigide"]}
                            getSortParams={setLongFlexibilitySortType}
                            setGenderSortValue={setLongFlexibilitySortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            isHome={true}
                        />
                        <Divider style={{marginTop: "10px"}}/>
                    </>
                        
                    

                    <>
                        <ShopShoeTransFlexibility
                            location={location}
                            usages={["Souple", "Medium", "Rigide"]}
                            getSortParams={setTransFlexibilitySortType}
                            setGenderSortValue={setTransFlexibilitySortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            isHome={true}
                        />
                        <Divider style={{marginTop: "10px"}}/>
                    </>
                    
                    {
                        mode == "auto" ?
                            <ShopMultiRangeSlider 
                                sortedHighPrice={maxPrice}
                                currentQuery={currentQuery}
                                setCurrentQuery={setCurrentQuery}
                                sortedLowPrice={minPrice}
                                getSortParams={getPriceSortParams}
                            />
                        : null
                    }
                    

                    <Divider />

                {/* filter by size */}
                {
                    location.pathname != "/products/accessories" && location.pathname != "/products/nutrition" && location.pathname != "/products/electronic"
                        ?
                        <>
                            <ShopSize
                                location={location}
                                currentQuery={currentQuery}
                                setCurrentQuery={setCurrentQuery}
                                sizes={newUniqueSizes}
                                getSortParams={getSizeSortParams} />
                            <Divider style={{marginTop: "10px"}}/>
                        </>


                        : null

                }

                {/* filter by tag */}
                <ShopTag
                    location={location}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                    tags={uniqueTags}
                    getSortParams={getBrandSortParams} />
                <Divider style={{marginTop: "10px"}}/>

                 {/* <ShopTagColor
                    location={location}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                    colors={uniqueColors}
                    getSortParams={getColorSortParams} />
                <Divider />*/}

                {/* filter by size 
                <ShopRetailer
                    location={location}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                    retailers={uniqueRetailers}
                    getSortParams={getRetailerSortParams} />*/}
            </div>

            <div style={{
                overflow: 'hidden',
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                backgroundColor: 'white',
                zIndex: 999,
                paddingBottom: '25px',
                paddingTop: '15px',
                borderTop: "2px solid #f7f7f7",
                textAlign:"center",
            }}>
                <button
                    style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius:'15px',
                        padding: "10px 20px",
                        border: "2px solid black",
                        fontSize: "13px",
                        fontWeight: 600,
                        marginRight: '50px'
                    }}
                    onClick={() => closeMobileMenu()}

                >Valider</button>
                <button
                    style={{
                        backgroundColor: "white",
                        color: "black",
                        borderRadius:'15px',
                        padding: "10px 20px",
                        border: "2px solid black",
                        fontSize: "13px",
                        fontWeight: 600
                    }}
                    onClick={() => {
                        getGenderSortParams("gender", "")
                        removeActiveGenderSort();
                        getSizeSortParams("size", "");
                        removeActiveSizeSort();
                        getBrandSortParams("brand", "");
                        removeActiveBrandsSort();
                        getColorSortParams("color", "");
                        removeActiveColorsSort();
                        getRetailerSortParams("retailer", "");
                        removeActiveRetailersSort();
                        getPriceSortParams("price", {min: sortedLowPrice, max: sortedHighPrice})
                        setCurrentQuery({ ...currentQuery, lowDrop: "", highDrop : "",lowWeigh: "", highWeigh : "",lowPrice: "", highPrice : "", filters: "Meilleures ventes", retailers: "", genders: "", sizes: "", brands: "", colors: "", pains: "", types: "", analysis: "", isPain: "", fitSizes: "", shoeDistance: "", usageType: "", shoeType: "", longFlexibility: "", transFlexibility: "", isMotor: ""});
                    }}
                >Réinitialiser</button>
            </div>
        </div>
    );
}

FilterMenu.propTypes = {
    getSortParams: PropTypes.func,
    getCategorySortParams: PropTypes.func,
    getGenderSortParams: PropTypes.func,
    getColorSortParams: PropTypes.func,
    getSizeSortParams: PropTypes.func,
    getPriceSortParams: PropTypes.func,
    getRetailerSortParams: PropTypes.func,
    products: PropTypes.array,
    sortedLowPrice: PropTypes.any,
    sortedHighPrice: PropTypes.any,
    sideSpaceClass: PropTypes.string,
    currentQuery: PropTypes.object,
    setCurrentQuery: PropTypes.func,
    location: PropTypes.string
};

// get individual sizes
export const newGetProductsIndividualSizes = (pathname, sizes) => {
    let productSizes = [];

    if (sizes){
        sizes.map(size => {
            if (size !== "questions les plus utiles"
                && size !== "questions les plus récentes"
                && size !== "questions les plus commentées"
                && size !== "" && newCheckExistingSize(pathname, size)) {
                return productSizes.push(size);
            } else {
                return productSizes
            }
        })
    }

    let uniqueSize = [...new Set(productSizes.sort())]
    const individualProductSizes = getIndividualItemArray(uniqueSize);
    return individualProductSizes;

};

export default FilterMenu;
