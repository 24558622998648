import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ReactGA from "react-ga4";
import {Button, Col, Row} from "react-bootstrap";
import ShopGender from "../../components/product/ShopGender";
import { parseCurrentQuery } from "../shop/ShopGridFullWidth";
import {useHistory} from "react-router";
import ShopMotorPref from "../../components/product/ShopMotorPref";
import ShopPain from "../../components/product/ShopPain";
import qs from "query-string";
import ShopShoeType from "../../components/product/ShopShoeType";
import ShopStrideAnalysis from "../../components/product/ShopStrideAnalysis";
import ShopShoeUsage from "../../components/product/ShopShoeUsage";
import ShopSize from "../../components/product/ShopSize";
import { getAvailablesSizes, getUniqueShoesSizeByGender } from "../shop-product/tools";
import ShopCompleteSize from "../../components/product/ShopCompleteSize";
import ShopShoeDistance from "../../components/product/ShopShoeDistance";
import ShopIsPain from "../../components/product/ShopIsPain";
import ShopIsMotorPref from "../../components/product/ShopIsMotorPref";
import { isMobileOnly } from "react-device-detect";
import ShopMultiRangeSlider from "../../components/MultiRangeSlider";
import ShopFitSize from "../../components/product/ShopFitSize";
import ShopShoeLongFlexibility from "../../components/product/ShopShoeLongFlexibility";
import ShopShoeTransFlexibility from "../../components/product/ShopShoeTransFlexibility";
import { getWithExpiry, setWithExpiry } from "../home/HomeFashionTwo";
import ToggleSwitch from "../../components/ToogleButton";
import ShopCushioningType from "../../components/product/ShopCushioningType";
import ShopMultiRangeDropSlider from "../../components/MultiRangeSliderDrop";
import ShopMultiRangeWeighSlider from "../../components/MultiRangeSliderWeigh";
import ShopCarbonPlate from "../../components/product/ShopCarbonPlate";
import ShopPlasticPlate from "../../components/product/ShopPlasticPlate";
import { Autocomplete, TextField } from "@mui/material";
import ShopProductChoice from "../../components/product/ShopProductChoice";

export function calculateShoeType(motorPref, painType, isPain, isMotor){
  var array = []
  if (isPain && isPain == "Non"){
    array = ["Coureur débutant", "Coureur régulier", "Axé performance"]
  }
  
  return array
}

const ProResearchHomePagePhysio = ({ location }) => {
  const { pathname } = location;
  const history = useHistory();

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
});
  const [currentQuery, setCurrentQuery] = useState(query)
  const [genderSortValue, setGenderSortValue] = useState('');
  const [genderSortType, setGenderSortType] = useState('');
  const [motorSortValue, setMotorSortValue] = useState('');
  const [cushioningTypeSortType, setCushioningTypeSortType] = useState('');
  const [cushioningTypeSortValue, setCushioningTypeSortValue] = useState('');
  const [carbonPlateSortType, setCarbonPlateSortType] = useState('');
  const [carbonPlateSortValue, setCarbonPlateSortValue] = useState('');
  const [plasticPlateSortType, setPlasticPlateSortType] = useState('');
  const [plasticPlateSortValue, setPlasticPlateSortValue] = useState('');
  const [motorSortType, setMotorSortType] = useState('');
  const [isMotorSortValue, setIsMotorSortValue] = useState('');
  const [isMotorSortType, setIsMotorSortType] = useState('');
  const [painSortValue, setPainSortValue] = useState('');
  const [painSortType, setPainSortType] = useState('');
  const [isPainSortValue, setIsPainSortValue] = useState('');
  const [isPainSortType, setIsPainSortType] = useState('');
  const [shoeTypeSortValue, setShoeTypeSortValue] = useState('');
  const [shoeTypeSortType, setShoeTypeSortType] = useState('');
  const [strideAnalysisSortValue, setStrideAnalysisSortValue] = useState('');
  const [strideAnalysisSortType, setStrideAnalysisSortType] = useState('');
  const [usageSortValue, setUsageSortValue] = useState('');
  const [usageSortType, setUsageSortType] = useState('');
  const [longFlexibilitySortValue, setLongFlexibilitySortValue] = useState('');
  const [longFlexibilitySortType, setLongFlexibilitySortType] = useState('');
  const [transFlexibilitySortValue, setTransFlexibilitySortValue] = useState('');
  const [transFlexibilitySortType, setTransFlexibilitySortType] = useState('');
  const [priceSortType, setPriceSortType] = useState('');
  const [dropSortType, setDropSortType] = useState('');
  const [weightSortType, setWeightSortType] = useState('');

  const [shoeUsageSortValue, setShoeUsageSortValue] = useState('');
  const [shoeUsageSortType, setShoeUsageSortType] = useState('');
  const [fitSizeSortValue, setFtSizeSortValue] = useState('');
  const [fitSizeSortType, setFtSizeSortType] = useState('');
  const [distanceSortValue, setDistanceSortValue] = useState('');
  const [distanceSortType, setDistanceSortType] = useState('');
  const [currentShoeType, setCurrentShoeType] = useState([])
  const [isPerformanceShoe, setIsPerformanceShoe] = useState(false)
  const [sizeSortValue, setSizeSortValue] = useState('');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(location && location.pathname == "/products/electronic" ? 1500 : 500);
  const [minDrop, setMinDrop] = useState(0);
  const [maxDrop, setMaxDrop] = useState(20);
  const [minWeight, setMinWeight] = useState(0);
  const [maxWeight, setMaxWeight] = useState(500);
  const [mode, setMode] = useState(getWithExpiry("trc-pro-client-manual") == "manual" ? "manual" : "auto");
  const [priceSortValue, setPriceSortValue] = useState(null);
  const [favoriteProducts, setFavoriteProducts] = useState([]);

  const getPriceSortParams = (priceSortType, priceSortValue) => {
    setPriceSortType(priceSortType);
    setPriceSortValue(priceSortValue);
}

  const handleClick = (pathname, currentQuery) => history.push({
    pathname: pathname,
    search: currentQuery
  });

  useEffect(() => {
    let favProducts = getWithExpiry("trc-pro-front-exported")
    if (favProducts){
        setFavoriteProducts(favProducts)
    }
}, [])

  useEffect(() => {
      if (currentQuery) {
        setCurrentShoeType(calculateShoeType(currentQuery.motor, currentQuery.pains, currentQuery.isPain, currentQuery.isMotor));
        handleClick(pathname, parseCurrentQuery(currentQuery));
        if(currentQuery.types == "Coureur expérimenté"){
          setIsPerformanceShoe(true)
        } else {
          setIsPerformanceShoe(false)
        }
      } else {
        setIsPerformanceShoe(false)
        setCurrentQuery([])
      }
  }, [currentQuery]);

  const onEventChange = (checked) => {
    setMode(checked ? "auto" : "manual");
    setCurrentQuery({ ...currentQuery, plasticPlate : "", motor: "", carbonPlate : "", pains: "", types: "", isPain: "", isMotor: "", lowDrop: "", shoeType: "", longFlexibility: "", transFlexibility : "", highDrop : "", lowWeigh: "", highWeigh : "", lowPrice: "", highPrice : "", cushioningType: "", retailers: "", genders: "", sizes: "", brands: "", colors: ""});
  };

  useEffect(() => {
    setWithExpiry("trc-pro-client-manual", mode, 566400000);
  }, [mode])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [])

  const handleSearch = (currentQuery) => {
    let query =  "/products/shoes?page=1"

    if (currentQuery.genders){
      query = query + "&genders=" + currentQuery.genders
    } 
    if (currentQuery.pains){
      query = query + "&pains=" + currentQuery.pains
    } 
    if (currentQuery.isPain){
      query = query + "&isPain=" + currentQuery.isPain
    } 
    if (currentQuery.isMotor){
      query = query + "&isMotor=" + currentQuery.isMotor
    } 
    if (currentQuery.motor){
      query = query + "&motor=" + currentQuery.motor
    } 
    if (currentQuery.types){
      query = query + "&types=" + currentQuery.types
    }    
    if (currentQuery.analysis){
      query = query + "&analysis=" + currentQuery.analysis
    }
    if (currentQuery.shoeType){
      query = query + "&shoeType=" + currentQuery.shoeType
    }
    if (currentQuery.shoeDistance){
      query = query + "&shoeDistance=" + currentQuery.shoeDistance
    }
    if (currentQuery.highPrice){
      query = query + "&highPrice=" + currentQuery.highPrice
    }
    if (currentQuery.lowPrice){
      query = query + "&lowPrice=" + currentQuery.lowPrice
    }
    if (currentQuery.fitSizes){
      query = query + "&fitSizes=" + currentQuery.fitSizes
    }
    if (currentQuery.longFlexibility){
      query = query + "&longFlexibility=" + currentQuery.longFlexibility
    }
    if (currentQuery.transFlexibility){
      query = query + "&transFlexibility=" + currentQuery.transFlexibility
    }
    if (currentQuery.lowPrice){
      query = query + "&lowPrice=" + currentQuery.lowPrice
    }
    if (currentQuery.highPrice){
      if (!currentQuery.lowPrice){
        query = query + "&lowPrice=0"
      }
      query = query + "&highPrice=" + currentQuery.highPrice
    }
    if (currentQuery.lowDrop){
      query = query + "&lowDrop=" + currentQuery.lowDrop
    }
    if (currentQuery.highDrop){
      query = query + "&highDrop=" + currentQuery.highDrop
    }
    if (currentQuery.lowWeigh){
      query = query + "&lowWeigh=" + currentQuery.lowWeigh
    }
    if (currentQuery.highWeigh){
      query = query + "&highWeigh=" + currentQuery.highWeigh
    }
    if (currentQuery.cushioningType){
      query = query + "&cushioningType=" + currentQuery.cushioningType
    }
    if (currentQuery.carbonPlate){
      query = query + "&carbonPlate=" + currentQuery.carbonPlate
    }
    if (currentQuery.plasticPlate){
      query = query + "&plasticPlate=" + currentQuery.plasticPlate
    }
    if (currentQuery.productName){
      query = query + "&productName=" + currentQuery.productName
    }
    if (currentQuery.productSlug){
      query = query + "&productSlug=" + currentQuery.productSlug
    }

    window.location.href = query
  }

  useEffect(() => {
    if(currentQuery && !currentQuery.carbonPlate){
      if(mode && mode == "manual"){
        setCurrentQuery({...currentQuery, carbonPlate: "Oui"})
      }
    }
    if(currentQuery && !currentQuery.plasticPlate){
      if(mode && mode == "manual"){
        setCurrentQuery({...currentQuery, plasticPlate: "Oui"})
      }
    }
    
  }, [mode, currentQuery]);

  return (
    <Fragment>
      <MetaTags>
        <title>Recherche</title>
        <meta
          name="description"
          content="The Running Collective."
        />
        <meta name="robots" content="noindex, nofollow"/>
      </MetaTags>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Accueil</BreadcrumbsItem>
      <LayoutOne
          headerContainerClass="container-fluid"
          headerPaddingClass="header-padding-2"
          headerTop="visible"
          favoriteProducts={favoriteProducts}
          setFavoriteProducts={setFavoriteProducts}
      location={location}>
        {/* breadcrumb */}
        <Breadcrumb />
        <div className={"error-area pb-100" + isMobileOnly ? " pt-20" : "pt-40"}>
          <div className="container">
          <Row className="pb-20 justify-content-end">
            <Col style={{paddingLeft: 0}} className="col-auto">
                <span style={{fontSize: "16px"}}>Mode : </span>
                <ToggleSwitch
                    id="newsletter"
                    optionLabels={["Automatique", "Manuel"]}
                    checked={mode == "auto"}
                    onChange={onEventChange}
                />
            </Col>
            </Row>
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 text-center">
                <div className="error">
                  {
                    mode == "auto" ?
                      <h2>Entrez les caractéristiques du patient :</h2>
                    : 
                      <h2>Caractéristiques recherchées :</h2>
                  }
                  <Row>
                    <Col>
                      <ShopGender
                        location={location}
                        genders={["Men", "Women", "Unisex"]}
                        getSortParams={setGenderSortType}
                        setGenderSortValue={setGenderSortValue}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        isHome={true}
                      />
                    </Col>
                  </Row>
                  {
                    mode == "manual" ?
                      <Row>
                        <Col>
                          <ShopCushioningType
                            location={location}
                            cushioningTypes={["Mou", "Ferme", "Adaptatif"]}
                            getSortParams={setCushioningTypeSortType}
                            setGenderSortValue={setCushioningTypeSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            isHome={true}
                          />
                        </Col>
                      </Row>
                    : null 
                  }
                  {
                    mode == "manual" ?
                      <Row>
                        <Col>
                          <ShopCarbonPlate
                            location={location}
                            carbonPlates={["Oui", "Non"]}
                            getSortParams={setCarbonPlateSortType}
                            setGenderSortValue={setCarbonPlateSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            isHome={true}
                          />
                        </Col>
                      </Row>
                    : null 
                  }
                  {
                    mode == "manual" ?
                      <Row>
                        <Col>
                          <ShopPlasticPlate
                            location={location}
                            plasticPlates={["Oui", "Non"]}
                            getSortParams={setPlasticPlateSortType}
                            setGenderSortValue={setPlasticPlateSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            isHome={true}
                          />
                        </Col>
                      </Row>
                    : null 
                  }

                  {
                    mode == "manual" ?
                    <Row className='justify-content-center'>
                      <Col xs={isMobileOnly ? 12 : 6}>
                        <ShopMultiRangeDropSlider
                          sortedHighPrice={maxDrop}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          sortedLowPrice={minDrop}
                          getSortParams={setDropSortType}
                          isHome={true}
                          isPhysio={true}
                        />
                      </Col>
                    </Row>
                    : null 
                  }

                  {
                    mode == "manual" ?
                    <Row className='justify-content-center'>
                      <Col xs={isMobileOnly ? 12 : 6}>
                        <ShopMultiRangeWeighSlider
                          sortedHighPrice={maxWeight}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          sortedLowPrice={minWeight}
                          getSortParams={setWeightSortType}
                          isHome={true}
                        />
                      </Col>
                    </Row>
                    : null 
                  }

                  {
                    mode == "manual" ?
                    <Row className='justify-content-center'>
                      <Col xs={isMobileOnly ? 12 : 6}>
                        <ShopMultiRangeSlider
                          sortedHighPrice={maxPrice}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          sortedLowPrice={minPrice}
                          getSortParams={setPriceSortType}
                          isHome={true}
                        />
                      </Col>
                    </Row>
                    : null 
                  }
                                   
                  {
                    mode == "auto" ?
                    <Row>
                      <Col>
                        <ShopIsPain
                          location={location}
                          pains={["Oui", "Non"]}
                          getSortParams={setIsPainSortType}
                          setGenderSortValue={setIsPainSortValue}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          isHome={true}
                          isPhysio={true}
                        />
                      </Col>
                    </Row>
                    : null
                  }
                  
                  {
                    mode == "auto" && currentQuery.isPain && currentQuery.isPain == "Oui" ?
                    <Row>
                      <Col>
                        <ShopPain
                          location={location}
                          pains={["Douleur pied-mollet", "Douleur genou-hanche-dos"]}
                          getSortParams={setPainSortType}
                          setGenderSortValue={setPainSortValue}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          isHome={true}
                        />
                      </Col>
                    </Row>
                    : null
                  }

                  {
                    mode == "auto" && currentShoeType && currentShoeType.length > 0 ?
                    <Row>
                      <Col>
                        <ShopShoeType
                          location={location}
                          types={currentShoeType}
                          getSortParams={setShoeTypeSortType}
                          setGenderSortValue={setShoeTypeSortValue}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          isHome={true}
                          isPhysio={true}
                        />
                      </Col>
                    </Row>
                    : null
                  }

                  {
                    mode == "auto" && isPerformanceShoe ? 
                    <Row>
                      <Col>
                        <ShopStrideAnalysis
                          location={location}
                          types={["Entrainement", "Performance"]}
                          getSortParams={setStrideAnalysisSortType}
                          setGenderSortValue={setStrideAnalysisSortValue}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          isHome={true}
                          isPhysio={true}
                        />
                      </Col>
                    </Row>
                    : null 
                  }

                  {
                    mode == "auto" ?
                      <Row className='justify-content-center'>
                        <Col xs={isMobileOnly ? 12 : 8}>
                          <ShopProductChoice
                            location={location}
                            usages={["Gravel", "Running", "Trail"]}
                            getSortParams={setUsageSortType}
                            setGenderSortValue={setUsageSortValue}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            isHome={true}
                          />
                        </Col>
                      </Row>
                    : null
                  }

                  {
                    mode == "auto" ?
                    <Row>
                      <Col>
                        <ShopFitSize
                          location={location}
                          fitsizes={["Serré", "Normal", "Large"]}
                          getSortParams={setFtSizeSortType}
                          setGenderSortValue={setFtSizeSortValue}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          isHome={true}
                        />
                      </Col>
                    </Row>
                    : null 
                  }

                  {
                    mode == "auto" ?
                    <Row className='justify-content-center'>
                      <Col xs={isMobileOnly ? 12 : 6}>
                        <ShopMultiRangeSlider
                          sortedHighPrice={maxPrice}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          sortedLowPrice={minPrice}
                          getSortParams={setPriceSortType}
                          isHome={true}
                        />
                      </Col>
                    </Row>
                    : null 
                  }

                  <Row>
                    <Col>
                      <ShopShoeDistance
                        location={location}
                        distances={["Courte distance (jusqu'à 10km)", "Longue distance"]}
                        getSortParams={setDistanceSortType}
                        setGenderSortValue={setDistanceSortValue}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        isHome={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <ShopShoeUsage
                        location={location}
                        usages={["Gravel", "Running", "Trail"]}
                        getSortParams={setUsageSortType}
                        setGenderSortValue={setUsageSortValue}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        isHome={true}
                      />
                    </Col>
                  </Row>

                  {/*<Row className='justify-content-center'>
                    <Col xs={isMobileOnly ? 12 : 6}>
                      <ShopMultiRangeSlider
                        sortedHighPrice={maxPrice}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        sortedLowPrice={minPrice}
                        getSortParams={setPriceSortType}
                        isHome={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <ShopFitSize
                        location={location}
                        fitsizes={["Serré", "Normal", "Large"]}
                        getSortParams={setFtSizeSortType}
                        setGenderSortValue={setFtSizeSortValue}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        isHome={true}
                      />
                    </Col>
                  </Row>*/}
                  

                  <Row style={{paddingTop: "10px", paddingBottom: "10px"}}>
                    <Col xs={12} style={{lineHeight: "16px"}}>
                      <i style={{fontSize: "11px"}}>L'utilisation de cet outil a pour objet de fournir des recommandations personnalisées dans le cadre du choix de chaussures de course à pied. Les résultats obtenus dépendent directement de l'exactitude des informations saisies par l'utilisateur. En cas d'insatisfaction de votre patient à la suite des conseils prodigués sur la base de cet outil, TRC ainsi que les concepteurs du sélecteur déclinent toute responsabilité et ne sauraient être tenus pour responsables des conséquences de son utilisation.</i>
                    </Col>
                  </Row>

                  <Row style={{marginTop: "10px", marginBottom: "40px"}}>
                    <Col xs={12}>
                      {
                        currentQuery && 
                        currentQuery.genders && currentQuery.genders !== "" && 
                        (currentQuery.pains && currentQuery.pains !== "" && 
                        ((currentShoeType.length > 0 && currentQuery.types && currentQuery.types !== "") ||
                        (currentShoeType.length == 0 && !currentQuery.types))) || (mode == "manual" && currentQuery.genders && currentQuery.genders !== "" && currentQuery.cushioningType && currentQuery.cushioningType !== "")?
                        <Button
                          type="submit"
                          fullWidth
                          onClick={() => handleSearch(currentQuery)}
                          variant="contained"
                          style={{height: "100%", width: "100%", padding: "10px", backgroundColor: "black", color: "white"}}
                      >
                          Rechercher
                      </Button>
                        : 
                          <Button
                            type="submit"
                            disabled
                            fullWidth
                            variant="contained"
                            style={{height: "100%", width: "100%", padding: "10px", backgroundColor: "black", color: "white"}}
                        >
                            Rechercher
                        </Button>
                       }
                      
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

ProResearchHomePagePhysio.propTypes = {
  location: PropTypes.object
};

export default ProResearchHomePagePhysio;


