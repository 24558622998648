import PropTypes from "prop-types";
import React, {useEffect, Suspense, lazy, useState} from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Logout from "./components/Logout";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import axios from "axios";
import {getWithExpiry, setWithExpiry} from "./pages/home/HomeFashionTwo";
import {useTranslation} from "react-i18next";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ProResearchHomePage from "./pages/other/ProResearchHomePage";
import ProResearchHomePagePhysio from "./pages/other/ProResearchHomePagePhysio";

// home pages
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridFullWidthResearched = lazy(() => import("./pages/shop/ShopGridFullWidthResearched"));
const ShopGridFullWidthFavorite = lazy(() => import("./pages/shop/ShopGridFullWidthFavorite"));

// product pages
const Product = lazy(() =>   import("./pages/shop-product/ProductTabRight"));
const Appointments = lazy(() =>   import("./pages/other/Appointments"));

const MyAccount = lazy(() => import("./pages/other/MyAccount"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const Redirection = lazy(() => import("./pages/other/Redirection"));

const SignInV2 = lazy(() => import("./pages/other/SignInV2"));
const RenewPasswordV2 = lazy(() => import("./pages/other/RenewPasswordV2"));

const getToken = () => {
  const tokenString = localStorage.getItem('trc-pro-client-token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
};

export const getClientTokenData = () => {
  const tokenString = localStorage.getItem('trc-pro-client-token');
  const userToken = JSON.parse(tokenString);
  return userToken?.user
};

//creating function to load ip address from the API
export const GetUserData = async (noSending, origin) => {
  var url = "https://pro.ip-api.com/json/?key=Y1hZznfd2inZrpm&fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query"
  const res = await axios.get(url)
  if (res.data){
    res.data.location = window.location.href
    var query = "https://public-front-api.therunningcollective.fr/data"
    if (!noSending) {
      var clientData = getClientTokenData()
      if (clientData) {
        res.data.email = clientData.email
        res.data.login = clientData.login
        res.data.firstName = clientData.firstName
        res.data.lastName = clientData.name
        res.data.clientID = clientData.id
      }
      axios.post(query, JSON.stringify(res.data))
    }
    return res.data
  }
}

const App = (props) => {
  const { i18n } = useTranslation();

  const [origin, setOrigin] = useState("fr")
  const [podoMode, setPodoMode] = useState(false)
  const [physioMode, setPhysioMode] = useState(false)

  useEffect(() => {
    setOrigin(i18n.language)
    document.documentElement.setAttribute('lang', i18n.language);
  }, [])

  let [token] = useState(getToken());

  ReactGA.initialize(
      [
        {
          trackingId: "G-XLY8JQ932W",
        },
      ], {testMode: false});

  useEffect(() => {
    ReactPixel.init('1219438635083129', {}, { debug: false, autoConfig: true });
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');

  }, []);

  useEffect(() => {
    const tokenString = localStorage.getItem('trc-pro-client-token');
    const userToken = JSON.parse(tokenString);
    if(userToken && userToken.professionalType){
      if (userToken.professionalType.indexOf("Podo") > -1) {
        setPodoMode(true)
      } else {
        setPhysioMode(true)
      }
    }
  }, [])


  useEffect(() => {
    if (window.location && window.location.search){
      const search = window.location.search; // could be '?foo=bar'
      const params = new URLSearchParams(search);
      const source = params.get('source'); // bar
      if (source){
        setWithExpiry("trc-source", source, 566400000)
      }
    }
  }, [])

  const theme = createTheme({
    typography: {
      fontFamily: [
        'ITC Avant Garde Gothic'
      ].join(','),
    }
  });

  return (
      <ThemeProvider theme={theme}>

      <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>

        <Router>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>

                {
                    token && podoMode ?
                      <Route
                        exact
                        path={process.env.PUBLIC_URL + "/"}
                        render={(routeProps) => (
                          <ProResearchHomePage {...routeProps} origin={origin} />
                        )}
                      />  
                    : token && physioMode ?
                        <Route
                        exact
                        path={process.env.PUBLIC_URL + "/"}
                        render={(routeProps) => (
                          <ProResearchHomePagePhysio {...routeProps} origin={origin} />
                        )}
                      />  
                    : 
                    <Route
                        exact
                        path={process.env.PUBLIC_URL + "/"}
                        component={SignInV2}
                    ></Route>
                }
                

                {/* Shop pages */}


                {
                    token ?
                    <Route
                      path={process.env.PUBLIC_URL + "/products"}
                      render={(routeProps) => (
                          <ShopGridFullWidth {...routeProps} origin={origin} />
                      )}
                    /> 
                    : 
                    <Route
                        path={process.env.PUBLIC_URL + "/products"}
                        component={SignInV2}
                    ></Route>
                }

                {
                    token ?
                    <Route
                      path={process.env.PUBLIC_URL + "/favorites"}
                      render={(routeProps) => (
                          <ShopGridFullWidthFavorite {...routeProps} origin={origin} />
                      )}
                    /> 
                    : 
                    <Route
                        path={process.env.PUBLIC_URL + "/favorites"}
                        component={SignInV2}
                    ></Route>
                }

                {
                    token ?
                    <Route
                      path={process.env.PUBLIC_URL + "/appointments"}
                      render={(routeProps) => (
                          <Appointments {...routeProps} origin={origin} />
                      )}
                    /> 
                    : 
                    <Route
                        path={process.env.PUBLIC_URL + "/appointments"}
                        component={SignInV2}
                    ></Route>
                }

                {
                    token ?
                    <Route
                        path={process.env.PUBLIC_URL + "/products-researched"}
                        component={ShopGridFullWidthResearched}
                    />
                    : 
                    <Route
                        path={process.env.PUBLIC_URL + "/products-researched"}
                        component={SignInV2}
                    ></Route>
                }

                {
                    token ?
                    <Route
                      path={process.env.PUBLIC_URL + "/products-researched/:id"}
                      render={(routeProps) => (
                          <ShopGridFullWidthResearched {...routeProps} key={routeProps.match.params.id} origin={origin} />
                      )}
                  />
                    : 
                    <Route
                      path={process.env.PUBLIC_URL + "/products-researched/:id"}
                      component={SignInV2}
                    ></Route>
                }
                
                {
                    token ?
                    <Route
                      path={process.env.PUBLIC_URL + "/products/:id"}
                      render={(routeProps) => (
                          <ShopGridFullWidth {...routeProps} key={routeProps.match.params.id} origin={origin} />
                      )}
                  />
                    : 
                    <Route
                    path={process.env.PUBLIC_URL + "/products/:id"}
                    component={SignInV2}
                    ></Route>
                }

                {
                    token ?
                    <Route
                      path={process.env.PUBLIC_URL + "/products/:id/:type"}
                      render={(routeProps) => (
                          <ShopGridFullWidth {...routeProps} key={routeProps.match.params.id} origin={origin} />
                      )}
                    />
                    : 
                    <Route
                    path={process.env.PUBLIC_URL + "/products/:id/:type"}
                    component={SignInV2}
                    ></Route>
                }

                {
                    token ?
                    <Route
                      path={process.env.PUBLIC_URL + "/product/:id"}
                      render={(routeProps) => (
                        <Product {...routeProps} key={routeProps.match.params.id} origin={origin} />
                      )}
                    />
                    : 
                    <Route
                      path={process.env.PUBLIC_URL + "/product/:id"}
                      component={SignInV2}
                    ></Route>
                }       

                {/* Shop product pages */}
                <Route
                    path={process.env.PUBLIC_URL + "/logout"}
                    component={Logout}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/renew-password"}
                    component={RenewPasswordV2}
                ></Route>
                {!token ?
                    <Route
                        path={process.env.PUBLIC_URL + "/login-register"}
                        component={SignInV2}
                    ></Route>
                    :
                    <Route
                        path={process.env.PUBLIC_URL + "/my-account"}
                        component={MyAccount}
                    />
                }

                {!token ?
                    <Route
                      path={process.env.PUBLIC_URL + "/my-account"}
                      component={SignInV2}
                    ></Route>
                    :
                    <Route
                        path={process.env.PUBLIC_URL + "/my-account"}
                        component={MyAccount}
                    />
                }

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/redirection"}
                    component={Redirection}
                />

                {
                    token ?
                      <Route exact component={NotFound} />
                    : 
                    <Route
                      exact
                      component={SignInV2}
                    ></Route>
                }
              </Switch>
            </Suspense>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
      </ThemeProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));

export function apiConversionRequest(queryUser, eventName, eventData){
  let fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]);
  let fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]);
  fbp = (fbp.length && fbp[0]) || null;
  fbc = (fbc.length && fbc[0]) || null;

  if(!fbc && window.location.search.includes('fbclid=')){
    fbc = 'fb.1.'+ (+new Date()) +'.'+ window.location.search.split('fbclid=')[1];
  }

  let body = {};

  if (eventData && eventData != ""){
    body = {
      "data": [
        {
          "event_name": eventName,
          "event_time": new Date().toISOString(),
          "user_data": {
            "fbp": fbp,
            "fbc": fbc,
            "client_user_agent": navigator.userAgent,
            "client_ip_address": queryUser,
          },
          "custom_data": {
            "value": eventData.value,
            "currency": "EUR",
            "content_ids": [
                eventData.productID
            ],
            "content_name": eventData.productName,
            "content_type": "product"
          },
          "event_source_url": window.location.origin + window.location.pathname,
          "action_source": "website"
        }
      ]
    }
  } else if (eventData && eventData != "Search"){
    body = {
      "data": [
        {
          "event_name": eventName,
          "event_time": new Date().toISOString(),
          "user_data": {
            "fbp": fbp,
            "fbc": fbc,
            "client_user_agent": navigator.userAgent,
            "client_ip_address": queryUser,
          },
          "custom_data": {
            "search_string": eventData.search,
            "content_type": "product"
          },
          "event_source_url": window.location.origin + window.location.pathname,
          "action_source": "website"
        }
      ]
    }
  } else {
    body = {
      "data": [
        {
          "event_name": eventName,
          "event_time": new Date().toISOString(),
          "user_data": {
            "fbp": fbp,
            "fbc": fbc,
            "client_user_agent": navigator.userAgent,
            "client_ip_address": queryUser,
          },
          "event_source_url": window.location.origin + window.location.pathname,
          "action_source": "website"
        }
      ]
    }
  }
  let query = "https://graph.facebook.com/v13.0/1219438635083129/events?access_token=EAAStngtBnw0BAEbbRh60krdUcZCFix7MLaxQoh59ZCgeWZAubEv27fBp2nFNS7r5kJKum4BfbhygIhUfyRFkfdq9sfpD4ege2ZCBMzZAYwJn5yFpI6QTcC2BySqMnmNAIXMysobqP3XmYjGjLqfVDKCEukJ2aGiYZCBGdIxb6h05uuDq9ZBUocj"

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  fetch(query, requestOptions)
      .then(response => response.json())
}