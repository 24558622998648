import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {getHighPrice, getLowPrice, getShowProducts, getSortedProducts} from '../../helpers/product';
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import ShopSidebar from '../../wrappers/product/ShopSidebar';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import ShopProducts from '../../wrappers/product/ShopProducts';
import axios from "axios";
import {multilanguage} from "redux-multilanguage";
import {isTablet, isMobileOnly} from 'react-device-detect';
import ReactGA from "react-ga4";
import {Col, Row, Spinner} from "react-bootstrap";
import qs from "query-string";
import {useHistory} from "react-router";
import {Helmet} from "react-helmet";
import {apiConversionRequest, GetUserData} from "../../App";
import ReactPixel from "react-facebook-pixel";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from "react-i18next";
import unslug from "unslug";
import ShopFilter from "../../components/product/ShopFilter";
import {Divider} from "@mui/material";
import Box from "@mui/material/Box";
import {StyledTab, StyledTabs} from "../../wrappers/header/HeaderTwo";
import {AiOutlineExport, BsChevronDown, BsChevronUp, BsNutFill, CiExport, GiRunningShoe, IoCloudDownloadOutline, IoResizeSharp, MdDensitySmall, MdLockReset, RiFolderUploadLine} from "react-icons/all";
import FilterMenu from "../../components/header/FilterMenu";
import shuffleArray from "../../wrappers/brand-logo/BrandLogoSliderOne"
import {getAvailablesSizes, getProfileSizes} from "../shop-product/tools"
import { getWithExpiry, setWithExpiry } from "../home/HomeFashionTwo";
import ExportProductsModal from "../../components/header/ExportProductsModal";
import ToggleSwitch from "../../components/ToogleButton";
import ShopSidebarPhysio from "../../wrappers/product/ShopSidebarPhysio";
import FilterMenuPhysio from "../../components/header/FilterMenuPhysio";


const ShopGridFullWidth = ({location, origin}) => {
    const [layout, setLayout] = useState('grid three-column');
    const [sortType, setSortType] = useState('filterSort');
    const [categorySortType, setCategorySortType] = useState('');
    const [categorySortValue, setCategorySortValue] = useState('');
    const [brandSortType, setBrandSortType] = useState('');
    const [brandSortValue, setBrandSortValue] = useState('');
    const [genderSortType, setGenderSortType] = useState('');
    const [genderSortValue, setGenderSortValue] = useState('');
    const [colorSortType, setColorSortType] = useState('');
    const [colorSortValue, setColorSortValue] = useState('');
    const [sizeSortType, setSizeSortType] = useState('');
    const [retailerSortType, setRetailerSortType] = useState('');
    const [retailerSortValue, setRetailerSortValue] = useState('');

    const [weighSortType, setWeighSortType] = useState('');
    const [weighSortValue, setWeighSortValue] = useState(null);
    const [dropSortType, setDropSortType] = useState('');
    const [dropSortValue, setDropSortValue] = useState(null);
    const [filtersSortType, setFiltersSortType] = useState('');
    const [filtersSortValue, setFiltersSortValue] = useState(null);

    const [sizeSortValue, setSizeSortValue] = useState('');
    const [priceSortType, setPriceSortType] = useState('');
    const [priceSortValue, setPriceSortValue] = useState(null);

    const [sortValue, setSortValue] = useState('default');
    const [filterSortType, setFilterSortType] = useState('');
    const [filterSortValue, setFilterSortValue] = useState('');
    const [pageLimit, setPageLimit] = useState(15);

    const [currentData, setCurrentData] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [sortedHighPrice, setSortedHighPrice] = useState(null);
    const [sortedLowPrice, setSortedLowPrice] = useState(null);
    const [sortedHighWeigh, setSortedHighWeigh] = useState(null);
    const [sortedLowWeigh, setSortedLowWeigh] = useState(null);
    const [sortedHighDrop, setSortedHighDrop] = useState(null);
    const [sortedLowDrop, setSortedLowDrop] = useState(null);
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const [currentQuery, setCurrentQuery] = useState(query);

    const {pathname} = location;
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [])

    const [initialProducts, setInitialProducts] = useState([]);
    const [availableSizes, setAvailableSizes] = useState([]);
    const [availableBrands, setAvailableBrands] = useState([]);
    const [availableColors, setAvailableColors] = useState([]);
    const [availableRetailers, setAvailableRetailers] = useState([]);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [genders, setGenders] = useState(["Men", "Women", "Unisex"]);
    const [countOfProducts, setCountOfProducts] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [favoriteProducts, setFavoriteProducts] = useState([]);
    const [mode, setMode] = useState(getWithExpiry("trc-pro-client-manual") == "auto" ? "auto" : "manual");

    const [podoMode, setPodoMode] = useState(false)
    const [physioMode, setPhysioMode] = useState(false)

    const [products, setProducts] = useState({ products: [] });
    const [error, setError] = useState('');
    const [loading, setloading] = useState(true);

    let parentCategory = location.pathname.split("/products").pop()

    const [isSmallScreen, setSmallScreen] = useState(false);

    let subCategory = query && query.category ? query.category : ''

    const history = useHistory();
    const handleClick = (pathname, currentQuery) => history.push({
        pathname: pathname,
        search: currentQuery
    });

    useEffect(() => {
        if (currentQuery) {
            handleClick(pathname, parseCurrentQuery(currentQuery))
        }
    }, [currentQuery]);

    useEffect(() => {
        const tokenString = localStorage.getItem('trc-pro-client-token');
        const userToken = JSON.parse(tokenString);
        if(userToken.professionalType){
            setloading(true)
          if (userToken.professionalType.indexOf("Podo") > -1) {
            setPodoMode(true)
          } else {
            setPhysioMode(true)
          }
        }
    }, [])

    useEffect(() => {
        let favProducts = getWithExpiry("trc-pro-front-exported")
        if (favProducts){
            setFavoriteProducts(favProducts)
        }
    }, [])

    const onEventChange = (checked) => {
        setMode(checked ? "auto" : "manual");
        setCurrentQuery({ ...currentQuery, lowDrop: "", shoeType: "", longFlexibility: "", transFlexibility : "", highDrop : "", lowWeigh: "", highWeigh : "", lowPrice: "", highPrice : "", cushioningType: "", retailers: "", genders: "", sizes: "", brands: "", colors: ""});
      };
    
      useEffect(() => {
        setWithExpiry("trc-pro-client-manual", mode, 566400000);
      }, [mode])

    const [dataStructured, setDataStructured] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [shopType, setShopType] = useState(query && query.shopType ? query.shopType : '');
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [minDrop, setMinDrop] = useState("");
    const [maxDrop, setMaxDrop] = useState("");
    const [cushioningType, setCushioningType] = useState("");
    const [openExportProductsModal, setOpenExportProductsModal] = useState(false);

    const { t, i18n } = useTranslation();

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = event.selected * pageLimit % products.length;
        setItemOffset(newOffset);
        setCurrentQuery({ ...currentQuery, page: event.selected+1})
    };

    const resetFavoritesProducts = () => {
        localStorage.removeItem('trc-pro-front-exported')
        setFavoriteProducts([])
        window.location.reload()
    }

    const fetchMerchandsData = () => {
        var lang = ""
        if(i18n.language == "be"){
          lang = "fr"
        } else {
          lang = i18n.language
        }
        let query = 'https://public-front-api.therunningcollective.fr/merchants?lang='+lang
    
        axios
            .get(query)
            .then((res) => {
              if (res.data.length > 0) {
                setAvailableRetailers(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
      };

      const fetchCategoriesData = (parentCategory) => {
        var lang = ""
        if(i18n.language == "be"){
          lang = "fr"
        } else {
          lang = i18n.language
        }
        let query = 'https://public-front-api.therunningcollective.fr/categories?headCategory='+parentCategory
    
        axios
            .get(query)
            .then((res) => {
              if (res.data.length > 0) {
                setAvailableCategories(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
      };

      const fetchBrandsData = (parentCategory) => {
        var lang = ""
        if(i18n.language == "be"){
          lang = "fr"
        } else {
          lang = i18n.language
        }
        let query = 'https://public-front-api.therunningcollective.fr/brands?headCategory=/products'+parentCategory
    
        axios
            .get(query)
            .then((res) => {
              if (res.data.length > 0) {
                setAvailableBrands(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
      };

      const fetchColorsData = (parentCategory) => {
        var lang = ""
        if(i18n.language == "be"){
          lang = "fr"
        } else {
          lang = i18n.language
        }
        let query = 'https://public-front-api.therunningcollective.fr/detailed-colors?headCategory=all'
    
        axios
            .get(query)
            .then((res) => {
              if (res.data.length > 0) {
                setAvailableColors(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
      };
    
      useEffect(() => {
        fetchBrandsData(parentCategory);
        fetchCategoriesData(parentCategory);
        fetchColorsData(parentCategory);
        fetchMerchandsData();
      }, [parentCategory]);

    useEffect(() => {
        if (query) {
            setGenderSortType(query && query.genders ? 'gender' : '');
            setGenderSortValue(query && query.genders ? query.genders : '');

            setCategorySortType(query && query.category ? 'category' : '');
            setCategorySortValue(query && query.category ? query.category : '');

            setColorSortType(query && query.colors ? 'color' : '');
            setColorSortValue(query && query.colors ? query.colors : '');

            setBrandSortType(query && query.brands ? 'brand' : '');
            setBrandSortValue(query && query.brands ? query.brands : '');

            setSizeSortType(query && query.sizes ? 'size' : '');
            setSizeSortValue(query && query.sizes ? query.sizes : '');

            setRetailerSortType(query && query.retailers ? 'retailer' : '');
            setRetailerSortValue(query && query.retailers ? query.retailers : '');

            setFiltersSortType(query && query.filters ? 'filters' : '');
            setFiltersSortValue(query && query.filters ? query.filters : '');

            //getPriceSortParams(0, 0)

            setShopType(query.shopType ? query.shopType : '')

            if(query.page && initialProducts){
                const newOffset = (query.page -1) * pageLimit % initialProducts.length;
                setItemOffset(newOffset)
                setCurrentPage(query.page -1)
            }
        }
    }, [query]);

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        } else {
            setSmallScreen(false)
        }
    }, [])

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        } else {
            setSmallScreen(true)
        }
    })

    useEffect(() => {
        if (isMobileOnly || isTablet){
            setPageLimit(16)
            setLayout('grid two-column')
        }
    }, [isMobileOnly, isTablet])

    useEffect(() => {
        if (products && products.length > 0) {
            let lowPrice = getLowPrice(products)
            let highPrice = getHighPrice(products)
            setSortedLowPrice(lowPrice)
            setSortedHighPrice(highPrice)
            setGenderSortValue('')
            setBrandSortValue('')
            setCategorySortValue('')
            setColorSortValue('')
            setRetailerSortValue('')

            ReactPixel.pageView();
            ReactPixel.fbq('track', 'PageView');
        }
    }, [products])

    const getLayout = (layout) => {
        setLayout(layout)
    }

    const getSortParams = (sortType, sortValue) => {
        setSortType(sortType);
        setSortValue(sortValue);
    }

    const getPriceSortParams = (priceSortType, priceSortValue) => {
        setPriceSortType(priceSortType);
        setPriceSortValue(priceSortValue);
    }

    const getWeighSortParams = (weighSortType, weighSortValue) => {
        setWeighSortType(weighSortType);
        setWeighSortValue(weighSortValue);
    }

    const getDropSortParams = (dropSortType, dropSortValue) => {
        setDropSortType(dropSortType);
        setDropSortValue(dropSortValue);
    }

    const getGenderSortParams = (genderSortType) => {
        setGenderSortType(genderSortType);
    }

    const getCategorySortParams = (categorySortType) => {
        setCategorySortType(categorySortType);
    }

    const getBrandSortParams = (brandSortType) => {
        setBrandSortType(brandSortType);
    }

    const getColorSortParams = (colorSortType) => {
        setColorSortType(colorSortType);
    }

    const getSizeSortParams = (sizeSortType) => {
        setSizeSortType(sizeSortType);
    }

    const getRetailerSortParams = (retailerSortType) => {
        setRetailerSortType(retailerSortType);
    }

    const getFilterSortParams = (sortType, sortValue) => {
        setFilterSortType(sortType);
        setFilterSortValue(sortValue);
    }

    const fetchInitialData = (parentCategory, pathname, currentQuery) => {
        let shopType = currentQuery.shoeType
        let page = currentQuery.page
        let category = currentQuery.category
        let filters = currentQuery.filters
        let genders = currentQuery.genders
        let sizes = currentQuery.sizes
        let colors = currentQuery.colors
        let brands = currentQuery.brands
        let retailers = currentQuery.retailers
        let lowPrice = currentQuery.lowPrice
        let highPrice = currentQuery.highPrice
        let keywords = currentQuery.keywords
        let painType = currentQuery.pains
        let motorPreference = currentQuery.motor
        let shoeType = currentQuery.types
        let analysis = currentQuery.analysis
        let shoeDistance = currentQuery.shoeDistance
        let fitSizes = currentQuery.fitSizes
        let longFlexibility = currentQuery.longFlexibility
        let transFlexibility = currentQuery.transFlexibility
        let minDrop = currentQuery.lowDrop
        let maxDrop = currentQuery.highDrop
        let minWeight = currentQuery.lowWeigh
        let maxWeight = currentQuery.highWeigh
        let cushioningType = currentQuery.cushioningType
        let plasticPlate = currentQuery.plasticPlate
        let carbonPlate = currentQuery.carbonPlate
        let productName = currentQuery.productName
        let productSlug = currentQuery.productSlug

        if (page == "") {
            page = "1"
        }

        if (origin && origin == "be"){
            origin = "fr"
        }

        let query = 'https://front-professional-api.therunningcollective.fr/products?lang='+origin+'&page=' + page
        if (shopType) {
            query = query + '&shopType=' + shopType.toLowerCase();
        }
        if (parentCategory !== "" && parentCategory !== "/") {
            query = query + '&headCategory=' + pathname
        }
        if (category) {
            query = query + '&subCategory=' + category
        }
        if (filters) {
            query = query + '&filters=' + filters
        }
        if (genders) {
            query = query + '&genders=' + genders
        }
        if (sizes) {
            query = query + '&sizes=' + getProfileSizes(sizes)
        }
        if (colors) {
            query = query + '&colors=' + colors
        }
        if (brands) {
            query = query + '&brands=' + brands
        }
        if (retailers) {
            query = query + '&retailers=' + retailers
        }
        if (lowPrice) {
            query = query + '&lowPrice=' + lowPrice
        }
        if (highPrice) {
            query = query + '&highPrice=' + highPrice
        }
        if (shoeType) {
            query = query + '&shoeType=' + shoeType
        }
        if (painType) {
            query = query + '&painType=' + painType
        }
        if (motorPreference) {
            query = query + '&motorPreference=' + motorPreference
        }
        if (fitSizes) {
            query = query + '&fitSizes=' + fitSizes
        }
        if (analysis) {
            query = query + '&analysis=' + analysis
        }
        if (((shoeDistance && shoeDistance.includes("Courte")) || (!shoeDistance)) && pathname == "/products/shoes"){
            query = query + '&shortDistance=true'
        }
        if (longFlexibility){
            query = query + "&longFlexibility=" + longFlexibility 
        }
        if (transFlexibility){
            query = query + "&transFlexibility=" + transFlexibility
        }
        if (minDrop){
            query = query + "&minDrop=" + minDrop
        }
        if (maxDrop){
            if (!minDrop){
                query = query + "&minDrop=" + 0
            }
            query = query + "&maxDrop=" + maxDrop
        }
        if (minWeight){
            query = query + "&minWeight=" + minWeight
        }
        if (maxWeight){
            query = query + "&maxWeight=" + maxWeight
        }
        if (cushioningType){
            query = query + "&cushionedType=" + cushioningType
        }
        if (mode == "manual"){
            query = query + "&manualMode=true"
        }
        if (carbonPlate && carbonPlate == "Oui"){
            query = query + "&carbonPlate=true"
        }
        if (carbonPlate && carbonPlate !== "Oui"){
            query = query + "&carbonPlate=false"
        }
        if (plasticPlate && plasticPlate == "Oui"){
            query = query + "&plasticPlate=true"
        }
        if (plasticPlate && plasticPlate != "Oui"){
            query = query + "&plasticPlate=false"
        }
        if (productName){
            query = query + "&productName=" + productName
        }
        if (productSlug){
            query = query + "&productSlug=" + productSlug
        }
        const tokenString = localStorage.getItem('trc-pro-client-token');
        const userToken = JSON.parse(tokenString);
        if(userToken.professionalType){
          if (userToken.professionalType.indexOf("Kiné") > -1) {
            query = query + "&isPhysio=true"
          }
        }

        if (keywords) {
            query = 'https://front-professional-api.therunningcollective.fr/search-products?keywords='+keywords
            axios
            .get(query, {
                headers: {
                  'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-pro-client-token')).token}`
                }})
            .then((res) => {
                setInitialProducts(res.data ? res.data : []);
                setHasMore(false)
                setTimeout(function () {
                    const indexString = localStorage.getItem('trc-client-old-page-view-token-index');
                    let indexToken = JSON.parse(indexString);
                    const oldPositionString = localStorage.getItem('trc-client-old-page-view-token-position-' + indexToken);
                    const oldPositionToken = JSON.parse(oldPositionString);

                    if (oldPositionToken && window.location.pathname.includes("/products/")) {
                        //window.scrollTo(0, Math.floor((oldPositionToken) - 100));
                        localStorage.setItem('trc-client-old-page-view-token-position-' + indexToken, JSON.stringify(oldPositionToken));
                    }
                }, 300);
                window.prerenderReady = true
            })
            .catch((err) => {
                setError(err);
                setHasMore(false);
                setloading(false);
                if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
                    window.location.href = "/logout"
                }
                window.prerenderReady = true
            })
            .finally(() => {
                setloading(false);
            });
        } else {
            axios
            .post(query, null ,{
                headers: {
                  'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-pro-client-token')).token}`
                }})
            .then((res) => {
                setInitialProducts(res.data.products);
                setGenders(["Men", "Women", "Unisex"])
                setHasMore(res.data.lastEvaluatedKey ? true : false)
                setLastEvaluatedKey(res.data.lastEvaluatedKey)
                setSortedLowPrice(res.data.lowPrice)
                setSortedHighPrice(res.data.highPrice)
                setSortedLowWeigh(res.data.lowWeigh)
                setSortedHighWeigh(res.data.highWeigh)
                setSortedLowDrop(res.data.minDrop)
                setSortedHighDrop(res.data.maxDrop)
                setCountOfProducts(res.data.products);
                setMinDrop(res.data.minDrop);
                setMaxDrop(res.data.maxDrop);
                setCushioningType(res.data.cushioningType);

                setTimeout(function () {
                    const indexString = localStorage.getItem('trc-client-old-page-view-token-index');
                    let indexToken = JSON.parse(indexString);
                    const oldPositionString = localStorage.getItem('trc-client-old-page-view-token-position-' + indexToken);
                    const oldPositionToken = JSON.parse(oldPositionString);

                    if (oldPositionToken && window.location.pathname.includes("/products/")) {
                        //window.scrollTo(0, Math.floor((oldPositionToken) - 100));
                        localStorage.setItem('trc-client-old-page-view-token-position-' + indexToken, JSON.stringify(oldPositionToken));
                    }
                }, 300);
                window.prerenderReady = true
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
                    window.location.href = "/logout"
                }
                setHasMore(false)
                window.prerenderReady = true
            })
            .finally(() => {
                setloading(false);
            });
        }        
    };

    const fetchMoreProductsData = (parentCategory, pathname, currentQuery, lastEvaluatedKey) => {
        let shopType = currentQuery.shoeType
        let page = currentQuery.page
        let category = currentQuery.category
        let filters = currentQuery.filters
        let genders = currentQuery.genders
        let sizes = currentQuery.sizes
        let colors = currentQuery.colors
        let brands = currentQuery.brands
        let retailers = currentQuery.retailers
        let lowPrice = currentQuery.lowPrice
        let highPrice = currentQuery.highPrice
        let keywords = currentQuery.keywords
        let painType = currentQuery.pains
        let motorPreference = currentQuery.motor
        let shoeType = currentQuery.types
        let analysis = currentQuery.analysis
        let shoeDistance = currentQuery.shoeDistance
        let fitSizes = currentQuery.fitSizes
        let longFlexibility = currentQuery.longFlexibility
        let transFlexibility = currentQuery.transFlexibility
        let minDrop = currentQuery.lowDrop
        let maxDrop = currentQuery.highDrop
        let minWeight = currentQuery.lowWeigh
        let maxWeight = currentQuery.highWeigh
        let cushioningType = currentQuery.cushioningType
        let plasticPlate = currentQuery.plasticPlate
        let carbonPlate = currentQuery.carbonPlate
        let productName = currentQuery.productName
        let productSlug = currentQuery.productSlug

        if (page == "") {
            page = "1"
        }

        if (origin && origin == "be"){
            origin = "fr"
        }

        let query = 'https://front-professional-api.therunningcollective.fr/products?lang='+origin+'&page=' + page
        if (shopType) {
            query = query + '&shopType=' + shopType.toLowerCase();
        }
        if (parentCategory !== "" && parentCategory !== "/") {
            query = query + '&headCategory=' + pathname
        }
        if (category) {
            query = query + '&subCategory=' + category
        }
        if (filters) {
            query = query + '&filters=' + filters
        }
        if (genders) {
            query = query + '&genders=' + genders
        }
        if (sizes) {
            query = query + '&sizes=' + getProfileSizes(sizes)
        }
        if (colors) {
            query = query + '&colors=' + colors
        }
        if (brands) {
            query = query + '&brands=' + brands
        }
        if (retailers) {
            query = query + '&retailers=' + retailers
        }
        if (lowPrice) {
            query = query + '&lowPrice=' + lowPrice
        }
        if (highPrice) {
            query = query + '&highPrice=' + highPrice
        }
        if (shoeType) {
            query = query + '&shoeType=' + shoeType
        }
        if (painType) {
            query = query + '&painType=' + painType
        }
        if (motorPreference) {
            query = query + '&motorPreference=' + motorPreference
        }
        if (analysis) {
            query = query + '&analysis=' + analysis
        }
        if (fitSizes) {
            query = query + '&fitSizes=' + fitSizes
        }
        if (((shoeDistance && shoeDistance.includes("Courte")) || (!shoeDistance)) && pathname == "/products/shoes"){
            query = query + '&shortDistance=true'
        }
        if (longFlexibility){
            query = query + "&longFlexibility=" + longFlexibility 
        }
        if (transFlexibility){
            query = query + "&transFlexibility=" + transFlexibility
        }
        if (minDrop){
            query = query + "&minDrop=" + minDrop
        }
        if (maxDrop){
            if (!minDrop){
                query = query + "&minDrop=" + 0
            }
            query = query + "&maxDrop=" + maxDrop
        }
        if (minWeight){
            query = query + "&minWeight=" + minWeight
        }
        if (maxWeight){
            if (!minWeight){
                query = query + "&minWeight=" + 0
            }
            query = query + "&maxWeight=" + maxWeight
        }
        if (cushioningType){
            query = query + "&cushionedType=" + cushioningType
        }
        if (mode == "manual"){
            query = query + "&manualMode=true"
        }
        if (carbonPlate && carbonPlate == "Oui"){
            query = query + "&carbonPlate=true"
        }
        if (plasticPlate && plasticPlate == "Oui"){
            query = query + "&plasticPlate=true"
        }
        if (productName){
            query = query + "&productName=" + productName
        }
        if (productSlug){
            query = query + "&productSlug=" + productSlug
        }
        const tokenString = localStorage.getItem('trc-pro-client-token');
        const userToken = JSON.parse(tokenString);
        if(userToken.professionalType){
          if (userToken.professionalType.indexOf("Kiné") > -1) {
            query = query + "&isPhysio=true"
          }
        }

        if (keywords) {
            query = 'https://front-professional-api.therunningcollective.fr/search-products?keywords='+keywords
            axios
            .get(query, {
                headers: {
                  'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-pro-client-token')).token}`
                }})
            .then((res) => {
                setInitialProducts(res.data ? res.data : []);
                setHasMore(false)
                setTimeout(function () {
                    const indexString = localStorage.getItem('trc-client-old-page-view-token-index');
                    let indexToken = JSON.parse(indexString);
                    const oldPositionString = localStorage.getItem('trc-client-old-page-view-token-position-' + indexToken);
                    const oldPositionToken = JSON.parse(oldPositionString);

                    if (oldPositionToken && window.location.pathname.includes("/products/")) {
                        //window.scrollTo(0, Math.floor((oldPositionToken) - 100));
                        localStorage.setItem('trc-client-old-page-view-token-position-' + indexToken, JSON.stringify(oldPositionToken));
                    }
                }, 300);
                window.prerenderReady = true
            })
            .catch((err) => {
                setError(err);
                setHasMore(false);
                setloading(false);
                if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
                    window.location.href = "/logout"
                }
                window.prerenderReady = true
            })
            .finally(() => {
                setloading(false);
            });
        } else {
            let body = null
            if (lastEvaluatedKey) {
                body = JSON.stringify(lastEvaluatedKey)
            }

            axios
            .post(query, body, {
                headers: {
                  'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-pro-client-token')).token}`
                }})
            .then((res) => {
                setInitialProducts(res.data.products ? [...initialProducts, ...res.data.products] : initialProducts && initialProducts.length > 0 ? initialProducts : []);
                setGenders(["Men", "Women", "Unisex"])
                setHasMore(res.data.lastEvaluatedKey ? true : false)
                setLastEvaluatedKey(res.data.lastEvaluatedKey)
                setSortedLowPrice(res.data.lowPrice)
                setSortedHighPrice(res.data.highPrice)
                setSortedLowWeigh(res.data.lowWeigh)
                setSortedHighWeigh(res.data.highWeigh)
                setSortedLowDrop(res.data.minDrop)
                setSortedHighDrop(res.data.maxDrop)
                setCountOfProducts(res.data.products);
                setMinDrop(res.data.minDrop);
                setMaxDrop(res.data.maxDrop);
                setCushioningType(res.data.cushioningType);
                setTimeout(function () {
                    const indexString = localStorage.getItem('trc-client-old-page-view-token-index');
                    let indexToken = JSON.parse(indexString);
                    const oldPositionString = localStorage.getItem('trc-client-old-page-view-token-position-' + indexToken);
                    const oldPositionToken = JSON.parse(oldPositionString);

                    if (oldPositionToken && window.location.pathname.includes("/products/")) {
                        //window.scrollTo(0, Math.floor((oldPositionToken) - 100));
                        localStorage.setItem('trc-client-old-page-view-token-position-' + indexToken, JSON.stringify(oldPositionToken));
                    }
                }, 300);
                window.prerenderReady = true
            })
            .catch((err) => {
                setError(err);
                setHasMore(false);
                setloading(false);
                if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
                    window.location.href = "/logout"
                }
                window.prerenderReady = true
            })
            .finally(() => {
                setloading(false);
            });
        }        
    };

    useEffect(() => {
        GetUserData().then(
            data => apiConversionRequest(data.query, "PageView")
        )
    }, [])

    useEffect(() => {
        fetchInitialData(parentCategory, pathname, currentQuery)
        let breadscrumb = {
            "@type": "BreadcrumbList",
            "@id": "https://app.therunningcollective."+origin+"/products/"+parentCategory+"#breadscrumb",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Accueil",
                    "item": "https://app.therunningcollective."+origin+"/"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": t(parentCategory),
                },
            ]
        }
        let structuredGlobal = {
            "@context": "https://schema.org",
            "@graph": [breadscrumb]
        }
        setDataStructured(structuredGlobal)
    }, [parentCategory, pathname, subCategory, currentQuery]);


    useEffect(() => {
        if(!query.page){
            setCurrentQuery({ page: 1})
        }
    }, [subCategory, query])

    const fetchMoreData = (parentCategory, pathname, currentQuery, lastEvaluatedKey) => {
        fetchMoreProductsData(parentCategory, pathname, currentQuery, lastEvaluatedKey)
    };

    useEffect(() => {
        if (!loading && initialProducts && initialProducts.length > 0) {
            let newInitialProducts = initialProducts
            let favProducts = getWithExpiry("trc-pro-front-exported")
            if (favProducts && favProducts.length > 0){
                let items = []
                favProducts.map(p =>{
                    p.isFavoriteItem = true
                    items.push(p)
                })
                newInitialProducts = items.concat(initialProducts)
            }
            
            let sortedProducts = getShowProducts(newInitialProducts)
            //sortedProducts = getSortedProducts(sortedProducts, sortType, sortValue, categorySortType, categorySortValue, brandSortType, brandSortValue, genderSortType, genderSortValue, colorSortType, colorSortValue, sizeSortType, sizeSortValue, priceSortType, priceSortValue, retailerSortType, retailerSortValue, weighSortType, weighSortValue, dropSortType, dropSortValue, filtersSortType, filtersSortValue);
            let filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue, categorySortType, categorySortValue, brandSortType, brandSortValue, genderSortType, genderSortValue, colorSortType, colorSortValue, sizeSortType, sizeSortValue, priceSortType, priceSortValue, retailerSortType, retailerSortValue, weighSortType, weighSortValue, dropSortType, dropSortValue, filtersSortType, filtersSortValue);
            filterSortedProducts =  removeDuplicateProduct(filterSortedProducts)

            setSortedProducts(filterSortedProducts);
            const endOffset = itemOffset + pageLimit;
            setCurrentData(sortedProducts.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(countOfProducts / pageLimit));
        } else if (initialProducts == null) {
            setSortedProducts([]);
        }
    }, [initialProducts, shopType, itemOffset, pageLimit, sortType, sortValue, categorySortType, categorySortValue, brandSortType, brandSortValue, genderSortType, genderSortValue, filterSortType, filterSortValue, colorSortType, colorSortValue, sizeSortType, sizeSortValue, priceSortType, priceSortValue, loading, sortedHighPrice, sortedLowPrice, retailerSortType, retailerSortValue, weighSortType, weighSortValue, dropSortType, dropSortValue, filtersSortType, filtersSortValue]);

    return (
        <Fragment>
            <MetaTags>
                <Helmet>
                    <title>{parentCategory == "" ? t('allproducts') : subCategory !== "" ? t(parentCategory) + " | " + t(subCategory) +  t('bestprice') : t(parentCategory) +  t('bestprice')}</title>
                    <meta name="description"
                          content={"The Running Collective, " + t('metatitle')} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content={"The Running Collective | " + (parentCategory == "" ? t('allproducts') : subCategory !== "" ? t(parentCategory) + " | " + t(subCategory) : t(parentCategory))} />
                    <meta property="og:type" content="article" />
                    <meta property="og:local" content={i18n.language == "en" ? "gb_GB" : i18n.language + "_"+ i18n.language.toUpperCase()} />
                    <meta property="og:description"
                          content={"The Running Collective, " + t('metatitle')} />
                    <meta property="og:image" content="https://img.therunningcollective.fr/head-03.jpg" />
                    {
                        dataStructured !== null ?
                            <script type="application/ld+json">
                                {JSON.stringify(dataStructured)}
                            </script>
                            :
                            null
                    }
                </Helmet>
            </MetaTags>

            <BreadcrumbsItem to={process.env.PUBLIC_URL + '/'}>{t('home')}</BreadcrumbsItem>
            {
                parentCategory !== "" ?
                    <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>{t(parentCategory)}</BreadcrumbsItem>
                    :
                    <BreadcrumbsItem to={process.env.PUBLIC_URL + location}>{currentQuery && currentQuery.keywords ? unslug(currentQuery.keywords) : t('allproducts')}</BreadcrumbsItem>
            }
            {subCategory !== "" ?
                <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname + "?category="+subCategory}>{t(subCategory)}</BreadcrumbsItem>
            : null
            }
            <LayoutOne
                location={location}
                physioMode={physioMode}
                favoriteProducts={favoriteProducts}
                setFavoriteProducts={setFavoriteProducts}
                headerContainerClass="container-fluid"
                headerPaddingClass="header-padding-2"
                headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb />
                <div style={{display: 'none'}}>
                    <h1>{"The Running Collective | " + (subCategory !== "" ? t(parentCategory) + " | " + t(subCategory) + t('bestprice') : t(parentCategory) + t('bestprice'))}</h1>
                    <h2>{t('h2home')}</h2>
                </div>

                {
                    isMenuOpen && podoMode ?
                        <FilterMenu
                            location={location}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            sortedHighPrice={sortedHighPrice}
                            sortedLowPrice={sortedLowPrice}
                            products={products}
                            uniqueGenders={genders}
                            uniqueCategories={availableCategories}
                            uniqueSizes={getAvailablesSizes(parentCategory)}
                            uniqueBrands={availableBrands}
                            uniqueColors={availableColors}
                            uniqueRetailers={availableRetailers}
                            getSortParams={getSortParams}
                            getCategorySortParams={getCategorySortParams}
                            getBrandSortParams={getBrandSortParams}
                            getGenderSortParams={getGenderSortParams}
                            getColorSortParams={getColorSortParams}
                            getSizeSortParams={getSizeSortParams}
                            getRetailerSortParams={getRetailerSortParams}
                            getPriceSortParams={getPriceSortParams}
                            setIsOpen={setIsMenuOpen}
                            pathname={pathname}
                            mode={mode}
                        />
                        : isMenuOpen && physioMode ? 
                        <FilterMenuPhysio
                            location={location}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            sortedHighPrice={sortedHighPrice}
                            sortedLowPrice={sortedLowPrice}
                            products={products}
                            uniqueGenders={genders}
                            uniqueCategories={availableCategories}
                            uniqueSizes={getAvailablesSizes(parentCategory)}
                            uniqueBrands={availableBrands}
                            uniqueColors={availableColors}
                            uniqueRetailers={availableRetailers}
                            getSortParams={getSortParams}
                            getCategorySortParams={getCategorySortParams}
                            getBrandSortParams={getBrandSortParams}
                            getGenderSortParams={getGenderSortParams}
                            getColorSortParams={getColorSortParams}
                            getSizeSortParams={getSizeSortParams}
                            getRetailerSortParams={getRetailerSortParams}
                            getPriceSortParams={getPriceSortParams}
                            setIsOpen={setIsMenuOpen}
                            pathname={pathname}
                            mode={mode}
                            setMinDrop={setMinDrop}
                            setMaxDrop={setMaxDrop}
                            minDrop={minDrop}
                            maxDrop={maxDrop}
                        />
                    : null
                }

                {loading ? <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" role="status" >
                                <span className="visually-hidden"></span>
                            </Spinner>
                            </div>
                    : (sortedProducts) ?
                    <div className={isMobileOnly ? "shop-area pb-100" : "shop-area pt-10 pb-100"}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-3 order-1 order-lg-1">

                                    {
                                        isMobileOnly ?
                                            <Row>
                                                <Col xs={6} style={{padding: "9px 45px",
                                                    borderBottom: "1px solid lightgray",
                                                    textAlign: "center"}} onClick={() => {setIsMenuOpen(!isMenuOpen)}}>
                                                    <div style={{ fontSize: "15px", paddingTop: isTablet ? "5px": "0px"}}>Filtres</div>
                                                </Col>
                                                <Col xs={6} style={{padding: "8px 45px",
                                                    borderBottom: "1px solid lightgray",
                                                    borderLeft: "1px solid lightgray",
                                                    textAlign: "center"}}
                                                     onClick={() => {setIsFilterMenuOpen(!isFilterMenuOpen)}}>
                                                    <div style={{fontSize: "15px", paddingTop: isTablet ? "5px": "0px"}}>Trier &nbsp; {!isFilterMenuOpen ? <BsChevronDown/> :<BsChevronUp/> }</div>
                                                </Col>
                                            </Row>
                                            : null
                                    }

                                    {
                                        isMobileOnly && isFilterMenuOpen ?
                                            <div>
                                                <ShopFilter
                                                    location={location}
                                                    currentQuery={currentQuery}
                                                    setCurrentQuery={setCurrentQuery}
                                                    filters={["Meilleures ventes", "Prix croissant", "Prix décroissant"]}
                                                    getSortParams={getFilterSortParams} />
                                                <Divider style={{marginTop: "10px"}}/>
                                            </div>
                                            : null
                                    }

                                    {
                                        isMobileOnly && (location.pathname == "/products/shoes" || location.pathname == "/products/apparel" || location.pathname == "/products") ?
                                            <Box sx={{bgcolor: 'background.paper' }} style={{marginBottom: "-10px"}}>
                                                <StyledTabs
                                                    centered
                                                    value={currentQuery && currentQuery.genders && currentQuery.genders == "Men" ? 0 : currentQuery && currentQuery.genders == "Women" ? 1 : null}
                                                    onChange={(event, newValue) => (setCurrentQuery({...currentQuery, genders: newValue == 0 ? "Men": "Women"}))}
                                                >
                                                    <StyledTab key="Men" label="Homme" />
                                                    <StyledTab key="Women" label="Femme" />
                                                </StyledTabs>
                                            </Box>
                                            : null
                                    }

                                    {/* shop sidebar */}
                                    {isMobileOnly || isSmallScreen ?
                                        null
                                        : currentQuery && currentQuery.keywords ? null :
                                        podoMode ?
                                        <ShopSidebar
                                            location={location}
                                            currentQuery={currentQuery}
                                            setCurrentQuery={setCurrentQuery}
                                            sortedHighPrice={sortedHighPrice}
                                            sortedLowPrice={sortedLowPrice}
                                            products={products}
                                            uniqueGenders={genders}
                                            uniqueCategories={availableCategories}
                                            uniqueSizes={getAvailablesSizes(parentCategory)}
                                            uniqueBrands={availableBrands}
                                            uniqueColors={availableColors}
                                            uniqueRetailers={availableRetailers}
                                            getSortParams={getSortParams}
                                            getCategorySortParams={getCategorySortParams}
                                            getBrandSortParams={getBrandSortParams}
                                            getGenderSortParams={getGenderSortParams}
                                            getColorSortParams={getColorSortParams}
                                            getSizeSortParams={getSizeSortParams}
                                            getRetailerSortParams={getRetailerSortParams}
                                            getPriceSortParams={getPriceSortParams}
                                            getWeighSortParams={getWeighSortParams}
                                            getDropSortParams={getDropSortParams}
                                            sortedHighWeigh={sortedHighWeigh}
                                            sortedLowWeigh={sortedLowWeigh}
                                            sortedHighDrop={sortedHighDrop}
                                            sortedLowDrop={sortedLowDrop}
                                            sideSpaceClass="mr-30"
                                            mode={mode}
                                        /> : physioMode ?
                                        <ShopSidebarPhysio
                                            location={location}
                                            currentQuery={currentQuery}
                                            setCurrentQuery={setCurrentQuery}
                                            sortedHighPrice={sortedHighPrice}
                                            sortedLowPrice={sortedLowPrice}
                                            products={products}
                                            uniqueGenders={genders}
                                            uniqueCategories={availableCategories}
                                            uniqueSizes={getAvailablesSizes(parentCategory)}
                                            uniqueBrands={availableBrands}
                                            uniqueColors={availableColors}
                                            uniqueRetailers={availableRetailers}
                                            getSortParams={getSortParams}
                                            getCategorySortParams={getCategorySortParams}
                                            getBrandSortParams={getBrandSortParams}
                                            getGenderSortParams={getGenderSortParams}
                                            getColorSortParams={getColorSortParams}
                                            getSizeSortParams={getSizeSortParams}
                                            getRetailerSortParams={getRetailerSortParams}
                                            getPriceSortParams={getPriceSortParams}
                                            getWeighSortParams={getWeighSortParams}
                                            getDropSortParams={getDropSortParams}
                                            sortedHighWeigh={sortedHighWeigh}
                                            sortedLowWeigh={sortedLowWeigh}
                                            sortedHighDrop={sortedHighDrop}
                                            sortedLowDrop={sortedLowDrop}
                                            sideSpaceClass="mr-30"
                                            mode={mode}
                                        /> : null
                                    }
                                </div>
                                <div className="col-lg-9 order-2 order-lg-2">
                                    <Row style={{paddingTop : isMobileOnly ? "20px" : "10px", paddingBottom: "10px"}}>
                                    {
                                        minDrop != "" || maxDrop != "" || (cushioningType && cushioningType !== "") ?
                                            <Col xs={isMobileOnly ? 12 : 6}>
                                                <div>
                                                    <span>Résultats de la recherche : </span>
                                                    &nbsp;&nbsp;
                                                    {
                                                        minDrop != "" && maxDrop != "" && minDrop == maxDrop ?
                                                        <span style={{backgroundColor: "rgb(233 237 235)", borderRadius: "10px", textAlign: "center", padding: "5px 10px"}}><GiRunningShoe/> : <span style={{fontSize:"13px"}}>{minDrop} {physioMode ? "" : "mm"}</span></span>
                                                        : minDrop != "" && maxDrop != "" ?
                                                        <span style={{backgroundColor: "rgb(233 237 235)", borderRadius: "10px", textAlign: "center", padding: "5px 10px"}}><GiRunningShoe/> : <span style={{fontSize:"13px"}}>{minDrop} à {maxDrop} {physioMode ? "" : "mm"}</span></span>
                                                        : null 
                                                    }
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {
                                                        cushioningType && cushioningType != "" ?
                                                            <span style={{backgroundColor: "rgb(233 237 235)", borderRadius: "10px", textAlign: "center", padding: "5px 10px"}}><MdDensitySmall/> : <span style={{fontSize:"13px"}}>{cushioningType}</span></span>
                                                        : BsNutFill
                                                    }
                                                    {
                                                        currentQuery && currentQuery.sizes ?
                                                        currentQuery.sizes.split("_").map(size => {
                                                            return(
                                                                <>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <span style={{backgroundColor: "rgb(233 237 235)", borderRadius: "10px", textAlign: "center", padding: "5px 10px"}}><IoResizeSharp/> : <span style={{fontSize:"13px"}}>{size}</span></span>
                                                                </>
                                                            )
                                                        })
                                                            
                                                        : null
                                                    }
                                                    
                                                </div>
                                            </Col>
                                            : <Col xs={6}></Col>
                                    }
                                    {
                                        favoriteProducts && favoriteProducts.length > 0 ?
                                        <Col xs={isMobileOnly ? 12 : 3} className={isMobileOnly ? "text-center" : "text-right"} style={{cursor: "pointer", marginTop: isMobileOnly ? "20px" : ""}} onClick={() => resetFavoritesProducts()}>
                                            
                                                    <span style={{padding : "10px 15px", color: "white", borderRadius: "5px", backgroundColor: "gray"}}>Réinitialiser la sélection &nbsp;&nbsp;<MdLockReset size={"1.3rem"}/></span>
                                        </Col>
                                        : null
                                    }
                                    {
                                        favoriteProducts && favoriteProducts.length > 0 ?
                                            <Col xs={isMobileOnly ? 12 : 3} className={isMobileOnly ? "text-center" : "text-right"} style={{cursor: "pointer", marginTop: isMobileOnly ? "20px" : ""}} onClick={() => setOpenExportProductsModal(true)}>
                                                
                                                        <span style={{padding : "10px 15px", color: "white", borderRadius: "5px", backgroundColor: "black"}}>Exporter la sélection &nbsp;&nbsp;<IoCloudDownloadOutline size={"1.3rem"}/></span>
                                                
                                            </Col>
                                        : null
                                    }
                                    </Row>

                                    
                                    {/* shop topbar default */}
                                    {
                                        sortedProducts && sortedProducts.length > 0 ?
                                            <InfiniteScroll
                                                scrollThreshold="50%"
                                                style={{overflow: "inherit"}}
                                                dataLength={sortedProducts.length}
                                                next={() => fetchMoreData(parentCategory, pathname, currentQuery, lastEvaluatedKey)}
                                                hasMore={hasMore}
                                                loader={<div style={{textAlign: 'center'}}>
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden"></span>
                                                    </Spinner>
                                                </div>}
                                            >
                                                <ShopProducts layout={layout} products={sortedProducts} setFavoriteProducts={setFavoriteProducts} favoriteProducts={favoriteProducts} physioMode={physioMode}/>
                                            </InfiniteScroll>
                                            : <div
                                                style={{textAlign: 'center', marginTop: '50px', marginBottom: '50px'}}>
                                                <span>Aucun produit disponible :(</span>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div style={{textAlign: 'center', marginTop: '50px', marginBottom: '50px'}}>
                            <span>Aucun produit disponible :(</span>
                        </div>  }
                {error ? <span>Erreur durant le chargement des données</span> : null }
                {
                    openExportProductsModal ?
                        <ExportProductsModal currentQuery={currentQuery} open={openExportProductsModal} setOpen={setOpenExportProductsModal} products={favoriteProducts}/>
                    : null
                }
            </LayoutOne>
        </Fragment>
    )
}

export function parseCurrentQuery(currentQuery) {
    let query = ""
    console.log(currentQuery)

    if ((currentQuery && (currentQuery.genders || currentQuery.cushioningType || currentQuery.productName || currentQuery.productSlug ||
        currentQuery.category || currentQuery.sizes || currentQuery.plasticPlate || currentQuery.carbonPlate ||
        currentQuery.colors || currentQuery.brands || currentQuery.page || currentQuery.retailers || currentQuery.product
        || currentQuery.filters || currentQuery.highPrice || currentQuery.lowPrice || currentQuery.longFlexibility
        || currentQuery.highWeigh || currentQuery.lowWeigh || currentQuery.shoeType || currentQuery.shoeDistance || currentQuery.transFlexibility
        || currentQuery.highDrop || currentQuery.lowDrop || currentQuery.type || currentQuery.isPain || currentQuery.isMotor
        || currentQuery.month || currentQuery.year || currentQuery.destination || currentQuery.fitSizes 
        || currentQuery.distance || currentQuery.deniv || currentQuery.stars || currentQuery.tag || currentQuery.keywords || currentQuery.analysis ||
        currentQuery.forecastID || currentQuery.pseudo || currentQuery.userID || currentQuery.motor || currentQuery.types || currentQuery.pains))) {
        query = "?"
        if (currentQuery.genders) {
            query = query + "genders=" + currentQuery.genders + "&"
        }

        if (currentQuery.category) {
            query = query + "category=" + currentQuery.category + "&"
        }
        if (currentQuery.sizes) {
            query = query + "sizes=" + currentQuery.sizes + "&"
        }
        if (currentQuery.brands) {
            query = query + "brands=" + currentQuery.brands + "&"
        }
        if (currentQuery.colors) {
            query = query + "colors=" + currentQuery.colors + "&"
        }
        if (currentQuery.retailers) {
            query = query + "retailers=" + currentQuery.retailers + "&"
        }
        if (currentQuery.shopType) {
            query = query + "shopType=" + currentQuery.shopType + "&"
        }
        if (currentQuery.product) {
            query = query + "product=" + currentQuery.product + "&"
        }
        if (currentQuery.filters) {
            query = query + "filters=" + currentQuery.filters + "&"
        }
        if (currentQuery.highPrice) {
            var highPrice = currentQuery.highPrice
            console.log(highPrice)
            if(typeof highPrice === "string" && currentQuery.highPrice.split(",") && currentQuery.highPrice.split(",").length > 0 && currentQuery.highPrice.split(",")[0]){
                highPrice = currentQuery.highPrice.split(",")[0]
            }
            query = query + "highPrice=" + highPrice + "&"
        }
        if (currentQuery.lowPrice) {
            var lowPrice = currentQuery.lowPrice
            if(typeof lowPrice === "string" && currentQuery.lowPrice.split(",") && currentQuery.lowPrice.split(",").length > 0 && currentQuery.lowPrice.split(",")[0]){
                lowPrice = currentQuery.lowPrice.split(",")[0]
            }
            query = query + "lowPrice=" + lowPrice + "&"
        }
        if (currentQuery.highWeigh) {
            query = query + "highWeigh=" + currentQuery.highWeigh + "&"
        }
        if (currentQuery.lowWeigh) {
            query = query + "lowWeigh=" + currentQuery.lowWeigh + "&"
        }
        if (currentQuery.highDrop) {
            query = query + "highDrop=" + currentQuery.highDrop + "&"
        }
        if (currentQuery.lowDrop) {
            query = query + "lowDrop=" + currentQuery.lowDrop + "&"
        }
        if (currentQuery.page) {
            query = query + "page=" + currentQuery.page + "&"
        }
        if (currentQuery.type) {
            query = query + "type=" + currentQuery.type + "&"
        }
        if (currentQuery.startDate) {
            query = query + "startDate=" + currentQuery.startDate + "&"
        }
        if (currentQuery.endDate) {
            query = query + "endDate=" + currentQuery.endDate + "&"
        }
        if (currentQuery.destination) {
            query = query + "destination=" + currentQuery.destination + "&"
        }
        if (currentQuery.distance) {
            query = query + "distance=" + currentQuery.distance + "&"
        }
        if (currentQuery.deniv) {
            query = query + "deniv=" + currentQuery.deniv + "&"
        }
        if (currentQuery.stars) {
            query = query + "stars=" + currentQuery.stars + "&"
        }
        if (currentQuery.tag) {
            query = query + "tag=" + currentQuery.tag + "&"
        }
        if (currentQuery.keywords) {
            query = query + "keywords=" + currentQuery.keywords + "&"
        }
        if (currentQuery.forecastID){
            query = query + "forecastID=" + currentQuery.forecastID + "&"
        }
        if (currentQuery.userID){
            query = query + "userID=" + currentQuery.userID + "&"
        }
        if (currentQuery.pseudo){
            query = query + "pseudo=" + currentQuery.pseudo + "&"
        }
        if (currentQuery.motor){
            query = query + "motor=" + currentQuery.motor + "&"
        }
        if (currentQuery.pains){
            query = query + "pains=" + currentQuery.pains + "&"
        }
        if (currentQuery.types){
            query = query + "types=" + currentQuery.types + "&"
        }
        if (currentQuery.analysis){
            query = query + "analysis=" + currentQuery.analysis + "&"
        }
        if (currentQuery.shoeType){
            query = query + "shoeType=" + currentQuery.shoeType + "&"
        }
        if (currentQuery.shoeDistance){
            query = query + "shoeDistance=" + currentQuery.shoeDistance + "&"
        }
        if (currentQuery.isPain){
            query = query + "isPain=" + currentQuery.isPain + "&"
        }
        if (currentQuery.isMotor){
            query = query + "isMotor=" + currentQuery.isMotor + "&"
        }
        if (currentQuery.fitSizes){
            query = query + "fitSizes=" + currentQuery.fitSizes + "&"
        }
        if (currentQuery.longFlexibility){
            query = query + "longFlexibility=" + currentQuery.longFlexibility + "&"
        }
        if (currentQuery.transFlexibility){
            query = query + "transFlexibility=" + currentQuery.transFlexibility + "&"
        }
        if (currentQuery.cushioningType){
            query = query + "cushioningType=" + currentQuery.cushioningType + "&"
        }
        if (currentQuery.carbonPlate){
            query = query + "carbonPlate=" + currentQuery.carbonPlate + "&"
        }
        if (currentQuery.plasticPlate){
            query = query + "plasticPlate=" + currentQuery.plasticPlate + "&"
        }
        if (currentQuery.productName){
            query = query + "productName=" + currentQuery.productName + "&"
        }
        if (currentQuery.productSlug){
            query = query + "productSlug=" + currentQuery.productSlug + "&"
        }
        

        query = query.slice(0, -1);
    }


    return query
}

function removeDuplicateProduct(products){
    let newItems = []
    products.map(product => {
      let notExists = true
      let shortURL = product.shortURL
      if (product.shortURL.split("?") && product.shortURL.split("?").length > 1){
        shortURL = product.shortURL.split("?")[0]
      }
      if(newItems.length > 0){
        newItems.map(newItem => {
            let newShortURL = newItem.shortURL
            if (newItem.shortURL.split("?") && newItem.shortURL.split("?").length > 1){
                newShortURL = newItem.shortURL.split("?")[0]
            }
            if (product.headCategory == newItem.headCategory && newShortURL == shortURL){
                notExists = false
              }
          })
        if(notExists){
            newItems.push(product)
        }
      } else {
        newItems.push(product)
      }
    })
    return newItems
}

ShopGridFullWidth.propTypes = {
  location: PropTypes.object,
}

export default multilanguage(ShopGridFullWidth);
