import PropTypes from "prop-types";
import React, { Fragment } from "react";
import HeaderOne from "../wrappers/header/HeaderOne";
import FooterOne from "../wrappers/footer/FooterOne";
import HeaderTwo from "../wrappers/header/HeaderTwo";
import {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {Col, Row, Spinner} from "react-bootstrap";
import ShopProducts from "../wrappers/product/ShopProducts";
import {slugify} from "../helpers/product";
import axios from "axios";
import qs from "qs";
import {isMobileOnly, isTablet} from "react-device-detect";
import RaceCard from "../components/races/RaceCard";
import ReactGA from "react-ga4";
import PodcastFeaturedSingle from "../components/podcast/PodcastFeaturedSingle";
import parse from "react-html-parser";
import { getWithExpiry } from "../pages/home/HomeFashionTwo";

const LayoutOne = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
  location,
  offer,
  homePage,
  favoriteProducts,
  setFavoriteProducts,
  physioMode
}) => {
  const [layout, setLayout] = useState('grid three-column');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const [currentQuery, setCurrentQuery] = useState(query);
  const [productSearch, setProductSearch] = useState(null);
  const [origin, setOrigin] = useState("it")
  const [raceSearch, setRaceSearch] = useState('');
  const [filteredRaces, setFilteredRaces] = useState([]);
  const [filteredPodcasts, setFilteredPodcasts] = useState([]);
  const [podcastSearch, setPodcastSearch] = useState('');
  const [actualFavProducts, setActualFavProducts] = useState(null)
  const [user, setUser] = useState(null)

  const getProfile = () => {
    ReactGA.event({
      category: 'User',
      action: 'Get profile information'
    });

    let query = 'https://front-professional-api.therunningcollective.fr/profile'
    axios.get(query, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-pro-client-token')).token}`
      }
      ,}
      )
        .then((res) => {
          setUser(res.data)
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
            window.location.href = "/logout"
          }
        })
  };

  useEffect(() => {
    let favProducts = getWithExpiry("trc-front-exported")
    if (favProducts && setFavoriteProducts){
      setFavoriteProducts(favProducts)
    } else if (favProducts && !setFavoriteProducts){
      setActualFavProducts(favProducts)
    }
  }, [])

  useEffect(() => {
    if(window.location.origin && window.location.origin.includes("https://app.therunningcollective.")){
      let origin = window.location.origin.split("https://app.therunningcollective.")
      setOrigin(origin)
    }
  })

  const getSearchRaces = (searchedRace) => {
    let race = slugify(searchedRace)

    ReactGA.event({
      category: 'Races',
      action: 'Search race ' + searchedRace
    });

    let query = 'https://public-front-api.therunningcollective.fr/search-races?race='+race
    axios.get(query, null)
        .then((res) => {
          setFilteredRaces(res.data)
          setHasMore(res.data.hasMore)
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
            window.location.href = "/logout"
          }
        })
  };

  const fetchSearchProductsData = (slug, page) => {
    let product = slugify(slug)
    if (product.length > 1) {
      let query = 'https://front-professional-api.therunningcollective.fr/search-products?lang='+origin+'&page='+page+'&keywords=' + product
      axios
          .get(query, {
            headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-pro-client-token')).token}`
            }})
          .then((res) => {
            setHasMore(res.data.hasMore)
            setFilteredProducts(res.data);
          })
          .catch((err) => {
            if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
              window.location.href = "/logout"
            }
          })
    }
  };

  const getSearchPodcasts = (searchedPodcast) => {
    let podcast = slugify(searchedPodcast)

    ReactGA.event({
      category: 'Podcasts',
      action: 'Search podcast ' + searchedPodcast
    });

    let query = 'https://public-front-api.therunningcollective.fr/search-podcasts?keywords='+podcast
    axios.get(query, null)
        .then((res) => {
          setFilteredPodcasts(res.data)
          setHasMore(false)
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
            window.location.href = "/logout"
          }
        })
  };

  useEffect(() => {
    getProfile()
  }, [])

  useEffect(() => {
    if (isMobileOnly || isTablet){
      setLayout('grid two-column')
    }
  }, [isMobileOnly, isTablet])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (productSearch && productSearch.length > 2) {
        let resWithoutSpace = productSearch.replaceAll(" ", "-")
        let res = resWithoutSpace.toLowerCase()
        setCurrentQuery({product: res})
        fetchSearchProductsData(productSearch, currentPage)
      } else {
        setCurrentQuery({product:""})
        setFilteredProducts(null)
      }
    }, 200)
    return () => clearTimeout(delayDebounceFn)
  }, [productSearch, currentPage])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (raceSearch && raceSearch.length > 2) {
        getSearchRaces(raceSearch)
      } else {
        setFilteredRaces(null)
      }
    }, 200)

    return () => clearTimeout(delayDebounceFn)

  }, [raceSearch])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (podcastSearch && podcastSearch.length > 2) {
        getSearchPodcasts(podcastSearch)
      } else {
        setFilteredPodcasts(null)
      }
    }, 200)

    return () => clearTimeout(delayDebounceFn)

  }, [podcastSearch])

    return (
    <Fragment>
      <HeaderTwo
        layout={headerContainerClass}
        top={headerTop}
        headerPaddingClass={headerPaddingClass}
        headerPositionClass={headerPositionClass}
        location={location}
        offer={offer}
        setProductSearch={setProductSearch}
        productSearch={productSearch}
        setRaceSearch={setRaceSearch}
        podcastSearch={podcastSearch}
        setPodcastSearch={setPodcastSearch}
        homePage={homePage}
        favoriteProducts={favoriteProducts ? favoriteProducts : actualFavProducts}
      />
        {
            filteredProducts && filteredProducts.length > 0 ?
                <InfiniteScroll
                    style={{overflow: "inherit"}}
                    dataLength={filteredProducts.length}
                    next={() => setCurrentPage(currentPage + 1)}
                    hasMore={hasMore}
                    loader={<div style={{textAlign: 'center'}}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden"></span>
                        </Spinner>
                    </div>}
                >

                    <div className="container">
                        <ShopProducts physioMode={physioMode} layout={layout} products={filteredProducts} setFavoriteProducts={setFavoriteProducts} favoriteProducts={favoriteProducts}/>
                    </div>
                </InfiniteScroll>
                : filteredRaces && filteredRaces.length > 0 ?
                    <InfiniteScroll
                        scrollThreshold="80%"
                        style={{overflow: "inherit"}}
                        dataLength={filteredRaces.length}
                        hasMore={hasMore}
                        loader={<div style={{textAlign: 'center'}}>
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden"></span>
                          </Spinner>
                        </div>}
                    >
                      <div className="container">

                        <Row>

                          {
                            filteredRaces && filteredRaces.length > 0 && filteredRaces.map(race => {
                              return (

                                  <Col xl={3} xs={6} style={{paddingBottom: "30px"}}>
                                    <RaceCard race={race}/>
                                  </Col>
                              )
                            })}
                        </Row>
                      </div>
                    </InfiniteScroll> : filteredPodcasts && filteredPodcasts.length > 0 ?
                        <InfiniteScroll
                            scrollThreshold="80%"
                            style={{overflow: "inherit"}}
                            dataLength={filteredPodcasts.length}
                            hasMore={hasMore}
                            loader={<div style={{textAlign: 'center'}}>
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden"></span>
                              </Spinner>
                            </div>}
                        >
                          <div className="container">

                            <Row>

                              {
                                filteredPodcasts.map(podcast => {
                                  return (
                                      <Col xs={isMobileOnly ? 6 : 3} style={{marginBottom: "20px", cursor:"pointer"}}
                                           onClick={() => window.location = process.env.PUBLIC_URL + '/podcast/'+podcast.id}>
                                        <div
                                            style={{height: isMobileOnly ? "280px" : "380px", overflow: "hidden",
                                              borderTop: "1px solid black",
                                              borderLeft: "1px solid black",
                                              borderRight: "1px solid black",
                                              padding: "8px",
                                              borderRadius: "10px 10px 0 0"
                                            }}>
                                          <div style={{
                                            overflow: "hidden",
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "5px"
                                          }}>
                                            <img
                                                style={{width:"100%", borderRadius: "5px"}}
                                                src={podcast.image}/>
                                          </div>
                                        </div>
                                        <div className="container" style={{
                                          borderLeft: "1px solid black",
                                          borderRight: "1px solid black"}}>
                                          <Row>
                                            {
                                              podcast.tags && podcast.tags.length > 2 ?
                                                  podcast.tags.slice(0,2).map(tag => {
                                                    return(
                                                        <Col xs={5} style={{
                                                          backgroundColor:"black", color:"white", padding:"1px", fontSize:"12px", textAlign:"center",
                                                          marginLeft:"10px", borderRadius:"10px"
                                                        }}>
                                                          {tag}
                                                        </Col>
                                                    )
                                                  })

                                                  : podcast.tags && podcast.tags.length > 0 ?
                                                      podcast.tags.map(tag => {
                                                        return(
                                                            <Col xs={5} style={{
                                                              backgroundColor:"black", color:"white", padding:"1px", fontSize:"12px", textAlign:"center",
                                                              marginLeft:"10px", borderRadius:"10px"
                                                            }}>
                                                              {tag}
                                                            </Col>
                                                        )
                                                      }) : null
                                            }
                                          </Row>
                                        </div>
                                        <div style={{fontSize: "15px", fontWeight: 500,
                                          borderLeft: "1px solid black",
                                          borderRight: "1px solid black", padding: "8px 8px 3px 8px",
                                          lineHeight: "16px"}}>
                                          {podcast.name}
                                        </div>
                                        <div style={{paddingLeft: "8px",
                                          paddingRight: "8px",
                                          borderBottom: "1px solid black",
                                          borderLeft: "1px solid black",
                                          borderRight: "1px solid black",
                                          borderRadius: "0 0 10px 10px",
                                          paddingBottom: "8px"}}>
                                          <p style={{fontSize: '10px', lineHeight: "12px"}} >
                                            {podcast && podcast.description && podcast.description != "" && podcast.description.length > 150 ?
                                                `${parse(podcast.description.substring(0, 150))}...` : parse(podcast.description)
                                            }
                                          </p>
                                        </div>
                                      </Col>
                                  )
                                })}
                            </Row>
                          </div>
                        </InfiniteScroll> : children }

    </Fragment>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
  location: PropTypes.object,
    offer: PropTypes.object,
  setRaceSearch: PropTypes.any,
};

export default LayoutOne;
